import { type FC } from 'react';
import { CenteredLayout } from '@/components/layouts/CenteredLayout';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { CardContent, CardPrimitiveRoot } from '@/components/primitives/Card';
import { Form } from '@/components/primitives/Form';
import { InputField } from '@/components/form/InputField';
import { Button } from '@/components/primitives/Button';
import { cn } from '@/utils/cn';
import { useBackgroundTheme } from '@/hooks/useBackgroundTheme';
import { isLightBackground } from '@/utils/color';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useForm } from 'react-hook-form';
import {
  createSignupMethodSchema,
  type SignupMethodValues,
} from '@/utils/zodSchema';
import { zodResolver } from '@hookform/resolvers/zod';

interface SignupEmailProps {
  onSubmit: (v: SignupMethodValues) => void;
}

const SignupEmail: FC<SignupEmailProps> = ({ onSubmit }) => {
  const backgroundTheme = useBackgroundTheme();
  const isLight = isLightBackground(backgroundTheme);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const form = useForm<SignupMethodValues>({
    mode: 'onBlur',
    resolver: zodResolver(createSignupMethodSchema(t)),
    defaultValues: {
      email: '',
    },
  });

  return (
    <CenteredLayout>
      <div className="mx-auto flex w-full max-w-[410px] flex-col gap-8 p-4 tablet:mb-auto tablet:mt-auto laptop:p-0">
        <Typography variant={isLaptopOrBigger ? 'title' : 'mobile-title'}>
          <TransText i18nKey="createAnAccount" />
        </Typography>

        <CardPrimitiveRoot className="rounded-xl">
          <CardContent className="flex flex-col gap-6">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4"
              >
                <InputField
                  control={form.control}
                  name="email"
                  label="Email"
                  className={isLaptopOrBigger ? 'h-8' : 'h-12'}
                  placeholder={t('text.enterYourEmailAddress')}
                  type="email"
                  autoCapitalize="none"
                  autoComplete="email"
                  autoCorrect="off"
                  required
                />
                <Button
                  type="submit"
                  data-testid="continue-with-email"
                  variant="primary"
                  className="h-12 w-full"
                >
                  <TransText i18nKey="continueWithEmail" />
                </Button>
              </form>
            </Form>
          </CardContent>
        </CardPrimitiveRoot>

        <div className="flex items-center gap-3 self-center">
          <Typography variant="body1">
            <TransText i18nKey="alreadyHaveAnAccount" />
          </Typography>
          <Button
            variant="ghost"
            className={cn('p-0', isLight ? 'text-primary' : 'text-accent')}
            onClick={() => navigate('/login')}
            data-testid="signup-to-login"
          >
            <Typography variant="button">
              <TransText i18nKey="logIn" />
            </Typography>
          </Button>
        </div>
      </div>
    </CenteredLayout>
  );
};

export default SignupEmail;
