import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import AncillaryRow from '@/components/purchase/checkout/ancillaries/AncillaryRow';
import type { BookedAncillaryDTO, LegDTO } from '@/types/dto';
import { TransText } from '@/i18n/trans/text';
import { useBookingAncillariesByLegId } from '@/hooks/useBookingAncillariesByLegId';
import { useSelector } from '@/store/utils';
import { purchaseFlowBookingSelector } from '@/features/purchase/purchaseSelectors';

interface LegSectionProps {
  leg: LegDTO;
  handleRemoveAncillary: (ancillary: BookedAncillaryDTO) => void;
}

const LegSection: FC<LegSectionProps> = ({ leg, handleRemoveAncillary }) => {
  const booking = useSelector(purchaseFlowBookingSelector);
  const ancillariesByLegId = useBookingAncillariesByLegId(booking);
  const selectedAncillaries = leg.id ? ancillariesByLegId[leg.id] : [];

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex flex-wrap items-center pt-2">
        <Typography variant="body2-bold">{leg.originStop?.name}</Typography>
        <Icons.oneWay className="mx-1 h-3 w-3 text-neutral" />
        <Typography variant="body2-bold">
          {leg.destinationStop?.name}
        </Typography>
      </div>
      {selectedAncillaries?.length ? (
        selectedAncillaries.map((ancillary, key) => (
          <AncillaryRow
            ancillary={ancillary}
            icon={
              <Icons.addon height={16} width={16} className="text-primary" />
            }
            handleRemoveAncillary={handleRemoveAncillary}
            key={key}
          />
        ))
      ) : (
        <Typography variant="body2" className="text-neutral">
          <TransText i18nKey="noExtrasAdded" />
        </Typography>
      )}
    </div>
  );
};

export default LegSection;
