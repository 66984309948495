import type { FC } from 'react';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import type { Flow } from '@/types/booking';
import HighlightedRow from '@/components/booking-summary/HighlightedRow';
import { format } from '@/utils/date-time';
import { useBookingNonTripOfferDetails } from '@/hooks/useBookingNonTripOfferDetails';
import { cn } from '@/utils/cn';

interface NonTripOfferDescriptionProps {
  flow: Flow;
}

const NonTripOfferDescription: FC<NonTripOfferDescriptionProps> = ({
  flow,
}) => {
  const { travelPass, multiRide } = useBookingNonTripOfferDetails(flow);

  return (
    <div className="flex flex-col">
      <HighlightedRow className="rounded-t-lg">
        <div className="flex gap-2">
          <Icons.transportFrom className="h-5 w-5 text-primary" />
          <Typography variant="body1-bold">
            <TransText i18nKey="travelPassDetails" />
          </Typography>
        </div>
      </HighlightedRow>
      <div className="flex flex-col gap-6 p-4">
        {[...travelPass, ...multiRide].map((offer, key) => {
          const zone =
            offer.travelAccount?.regionalConstraint?.regionalValidities[0].zone;
          const origin = zone?.entryStation?.name;
          const destination = zone?.terminalStation?.name;
          const isRefunded = offer.status === 'REFUNDED';

          return (
            <div className="flex flex-col" key={key}>
              <div className="mb-4 flex flex-row gap-2">
                <div className="flex w-fit flex-col">
                  <div className="h-4 w-4">
                    <Icons.destination
                      height={12}
                      width={12}
                      className={cn('mx-auto text-primary', {
                        'text-neutral': isRefunded,
                      })}
                    />
                  </div>
                  <div className="mx-auto h-full w-0.5 bg-neutral-light" />
                  <div className="h-4 w-4">
                    <Icons.destination
                      height={12}
                      width={12}
                      className={cn('mx-auto text-primary', {
                        'text-neutral': isRefunded,
                      })}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <Typography
                    variant="body2-bold"
                    className={cn({
                      'text-neutral line-through decoration-error-border':
                        isRefunded,
                    })}
                  >
                    {origin}
                  </Typography>
                  <Typography
                    variant="body2-bold"
                    className={cn({
                      'text-neutral line-through decoration-error-border':
                        isRefunded,
                    })}
                  >
                    {destination}
                  </Typography>
                </div>
              </div>
              <div>
                <div className="flex gap-1">
                  <Typography className="text-neutral" variant="body2">
                    <TransText i18nKey="activation" />
                    &#58;
                  </Typography>
                  {offer.validFrom && (
                    <Typography variant="body2-bold">
                      {format(offer.validFrom, 'dd MMM, yyyy')}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NonTripOfferDescription;
