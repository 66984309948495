import type { ReactNode } from 'react';
import React, { createContext, useCallback, useState } from 'react';
import { useDispatch } from '@/store/utils';
import {
  getInboundJourneys,
  getNonTripOffers,
  getOutboundJourneys,
  resetPurchase,
  setSearchFormValues,
} from '@/features/purchase/purchaseActions';
import type { SearchFormValues } from '@turnit-ride-ui/webshop-search-widget/widget';
import { STEP } from '@/utils/purchase';
import { v4 } from 'uuid';
import { formatFullDate } from '@/utils/date-time';
import { setQueryParams } from '@turnit-ride-ui/webshop-search-widget/widget/utils/query';
import type { EngineSearchFormValues } from '@/types/search';

export interface SearchWidgetContextType {
  isSearchOverlayOpened: boolean;
  setIsSearchOverlayOpened: (isOpened: boolean) => void;
  handleSubmit: (values: SearchFormValues) => void;
  updateAndSubmit: (updatedValues: Partial<SearchFormValues>) => void;
}

export const SearchWidgetContext = createContext<
  SearchWidgetContextType | undefined
>(undefined);

export const SearchWidgetProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isSearchOverlayOpened, setIsSearchOverlayOpened] = useState(false);
  const [currentSearchValues, setCurrentSearchValues] =
    useState<SearchFormValues | null>(null);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values: SearchFormValues) => {
      setCurrentSearchValues(values);
      const isTicketProductType = values.productType === 'ticket';

      dispatch(
        resetPurchase({
          startStep: isTicketProductType
            ? STEP.OutboundJourney
            : STEP.NonTripOffer,
        })
      );
      setIsSearchOverlayOpened(false);

      const passengers = values.passengers.map(
        (passenger): EngineSearchFormValues['passengers'][number] => ({
          ...passenger,
          type: 'PERSON',
          externalReference: v4(),
        })
      );

      dispatch(setSearchFormValues({ ...values, passengers }));

      if (!values.origin?.value || !values.destination?.value) {
        return;
      }

      if (isTicketProductType) {
        dispatch(
          getOutboundJourneys({
            originStop: { code: values.origin.value },
            destinationStop: { code: values.destination.value },
            departureTime: formatFullDate(values.departureDate),
            passengers,
            ...(values.returnDate
              ? {
                  returnSearchParameters: {
                    inwardReturnDate: formatFullDate(values.returnDate),
                  },
                }
              : {}),
          })
        );

        if (values.returnDate) {
          dispatch(
            getInboundJourneys({
              originStop: { code: values.destination.value },
              destinationStop: { code: values.origin.value },
              departureTime: formatFullDate(values.returnDate),
              passengers,
              returnSearchParameters: {
                outwardTripDate: formatFullDate(values.departureDate),
              },
            })
          );
        }
      } else {
        dispatch(
          getNonTripOffers({
            originStop: { code: values.origin.value },
            destinationStop: { code: values.destination.value },
            validityStartTime: formatFullDate(values.departureDate),
            passengers,
          })
        );
      }
    },
    [dispatch]
  );

  const updateAndSubmit = useCallback(
    (updatedValues: Partial<SearchFormValues>) => {
      if (!currentSearchValues) {
        return;
      }

      const newValues = { ...currentSearchValues, ...updatedValues };

      setQueryParams(newValues);
      handleSubmit(newValues);
    },
    [currentSearchValues, handleSubmit]
  );

  return (
    <SearchWidgetContext.Provider
      value={{
        isSearchOverlayOpened,
        setIsSearchOverlayOpened,
        handleSubmit,
        updateAndSubmit,
      }}
    >
      {children}
    </SearchWidgetContext.Provider>
  );
};
