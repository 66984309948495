import { useCallback, useState } from 'react';
import { type SignupMethodValues } from '@/utils/zodSchema';
import SignupForm from '@/routes/signup/SignupForm';
import { withAuthGuard } from '@/hoc/withAuthGuard';
import { usePageTitle } from '@/hooks/usePageTitle';
import SignupEmail from '@/routes/signup/SignupEmail';

const SignUpComponent = () => {
  usePageTitle('signUp');
  const [step, setStep] = useState<'email' | 'form'>('email');
  const [email, setEmail] = useState('');

  const onSubmit = useCallback(async (data: SignupMethodValues) => {
    setEmail(data.email);
    setStep('form');
  }, []);

  return step === 'form' ? (
    <SignupForm email={email} />
  ) : (
    <SignupEmail onSubmit={onSubmit} />
  );
};

const SignUp = withAuthGuard('loginOrSignup')(SignUpComponent);

export default SignUp;
