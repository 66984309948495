import React, { type FC } from 'react';
import { Icons } from '@/components/icons';
import {
  SheetRoot,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/primitives/Sheet';
import type { NavigationItem } from '@/types/navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import MobileLanguageSelect from '@/components/header/language-select/MobileLanguageSelect';
import { Button } from '@/components/primitives/Button';
import MobileTenantSelect from '@/components/header/tenant-select/MobileTenantSelect';
import { isLocalDevelopment } from '@/utils/isLocalDev';
import { useSelector } from '@/store/utils';
import { tenantSelector } from '@/features/configuration/configurationSelector';
import { Link } from '@/components/primitives/Link';
import { getTenantConfiguration } from '@/utils/tenant';

type MobileNavMenuProps = {
  menuItems: Array<NavigationItem>;
};

const MobileNavMenu: FC<MobileNavMenuProps> = ({ menuItems }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedTenant = useSelector(tenantSelector);
  const TenantIcon = Icons[selectedTenant.value];
  const configuration = getTenantConfiguration(selectedTenant.value);

  return (
    <div
      className="flex w-full items-center justify-between gap-2 tablet:px-0"
      data-testid="navbar-mobile"
    >
      <Link to={configuration?.webPageUrl ?? '/'} aria-label="Hero">
        <TenantIcon />
      </Link>
      <div className="flex h-full items-center gap-2">
        {isLocalDevelopment() && (
          <>
            <MobileTenantSelect />
            <Button data-testid="my-account-button" variant="ghostLink">
              <Icons.user onClick={() => navigate('/login')} />
            </Button>
          </>
        )}
        <MobileLanguageSelect />
        <SheetRoot>
          <SheetTrigger
            className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-tint focus-visible:ring-offset-2"
            aria-label="Open navigation menu"
            asChild
          >
            <Button variant="ghostLink" data-testid="mobile-nav-open-button">
              <Icons.burgerMenu width={38} height={38} />
            </Button>
          </SheetTrigger>
          <SheetContent className="flex w-[250px] flex-col p-0" showClose>
            <SheetTitle className="sr-only">Mobile navigation menu</SheetTitle>
            <SheetDescription className="sr-only">
              Mobile navigation
            </SheetDescription>
            <SheetHeader className="border-b border-neutral-pale p-4">
              <TenantIcon />
            </SheetHeader>
            <nav>
              <div className="flex flex-col gap-2">
                {menuItems.map((item) => (
                  <SheetClose asChild key={item.path}>
                    <Link
                      className="w-fit px-4"
                      to={item.path}
                      onClick={item.onMenuItemClick}
                      active={item.path === location.pathname}
                    >
                      {item.name}
                    </Link>
                  </SheetClose>
                ))}
              </div>
            </nav>
          </SheetContent>
        </SheetRoot>
      </div>
    </div>
  );
};

export default MobileNavMenu;
