import type { FC, ReactNode } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import { cn } from '@/utils/cn';
import Divider from '@/components/primitives/Divider';
import { Radio } from '@/components/primitives/Radio';
import { Button } from '@/components/primitives/Button';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { TransText } from '@/i18n/trans/text';
import Refundability from '@/components/purchase/card/Refundability';
import Changeability from '@/components/purchase/card/Changeability';
import ExpandableText from '@/components/ExpandableText';
import PriceDisplay from '@/components/PriceDisplay';

interface BaseOfferCardProps {
  title: string;
  amount: number;
  isSelected: boolean;
  onSelect: () => void;
  description?: string;
  isRefundable?: boolean;
  isChangeable?: boolean;
  showPassengerInfo?: boolean;
  expandableContent?: ReactNode;
  testId: string;
}

const BaseOfferCard: FC<BaseOfferCardProps> = ({
  title,
  amount,
  isSelected,
  onSelect,
  description,
  isRefundable = false,
  isChangeable = false,
  showPassengerInfo = false,
  expandableContent,
  testId,
}) => {
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  return (
    <div
      className={cn(
        'flex w-full flex-col justify-between overflow-hidden rounded-lg border border-primary hover:cursor-pointer hover:bg-primary-pale',
        { 'border-2 border-primary-dark bg-primary-light': isSelected }
      )}
      onClick={onSelect}
    >
      <div className="flex flex-grow flex-col gap-1 p-2 laptop:gap-0">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row gap-2">
            {isLaptopOrBigger && (
              <Radio checked={isSelected} readOnly aria-label="Select offer" />
            )}
            <Typography variant="body1-bold">
              <span>{title}</span>
            </Typography>
          </div>
        </div>
        <Typography
          variant="body1-bold"
          className={cn('flex pl-6 text-primary', {
            'pl-0': !isLaptopOrBigger,
          })}
        >
          <PriceDisplay amount={amount} />
        </Typography>
        <Divider className="my-3" variant={isSelected ? 'medium' : 'regular'} />
        <div className="flex flex-col gap-1">
          {description && (
            <ExpandableText className="mb-3" text={description} />
          )}
          {expandableContent}
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row gap-1">
            <Refundability condition={isRefundable} />
            <Changeability condition={isChangeable} />
          </div>
          {showPassengerInfo && (
            <div className="mt-2 flex flex-row gap-1">
              <Icons.users className="text-primary" />
              <Typography variant="body2">
                <TransText i18nKey="priceIsForAllPassengers" />
              </Typography>
            </div>
          )}
        </div>
      </div>
      {!isLaptopOrBigger && (
        <Button
          variant={isSelected ? 'primary' : 'secondary'}
          data-testid={testId}
          className={cn('mt-1 w-full rounded-none border-0', {
            'border-t': !isSelected,
          })}
        >
          {isSelected && (
            <Icons.checkCircle width={20} height={20} className="self-center" />
          )}
          <Typography variant="body1-bold">
            <TransText i18nKey={isSelected ? 'selected' : 'select'} />
          </Typography>
        </Button>
      )}
    </div>
  );
};

export default BaseOfferCard;
