import type { CheckoutValues } from '@/utils/zodSchema';
import isEmpty from 'lodash/isEmpty';
import { Fragment, type ReactNode } from 'react';
import { TransText } from '@/i18n/trans/text';
import type { EngineSearchFormValues } from '@/types/search';
import type { OfferSearchRequestBodyDTO } from '@/types/dto';

// this method needs to have translate="no" prop assigned to the surrounding container to avoid breaking the app when "Translate to x" is used in Chrome
export const getPassengerName = (
  { firstName, lastName }: Partial<CheckoutValues['passengers'][number]>,
  index: number
) => {
  const fullName = [firstName, lastName].filter(Boolean).join(' ');

  return isEmpty(fullName) ? (
    <TransText i18nKey="passengerIndex" values={{ count: index + 1 }} />
  ) : (
    fullName
  );
};

export const getPassengerOtherDetails = ({
  age,
}: CheckoutValues['passengers'][number]) => {
  const details: Array<ReactNode> = [];

  if (age) {
    details.push(
      <TransText i18nKey="numberOfYearsOld" values={{ count: age }} />
    );
  }

  return details.map((detail, index) => (
    <Fragment key={index}>
      {index > 0 && ', '}
      {detail}
    </Fragment>
  ));
};

export const getOffersSearchPassengerSpecificationsPayload = (
  passengers: EngineSearchFormValues['passengers']
): OfferSearchRequestBodyDTO['passengerSpecifications'] => {
  const payload = passengers.map(({ age, externalReference, type }) => ({
    age,
    externalReference,
    type,
  }));

  return [payload[0], ...payload.slice(1)];
};
