import { STORAGE_KEYS } from '@/hooks/useStorage';

export const getTokensRef = () =>
  sessionStorage.getItem(STORAGE_KEYS.TOKENS_REF);
export const getTokenExpiry = () =>
  sessionStorage.getItem(STORAGE_KEYS.TOKEN_EXPIRY);

export const setTokensRef = (tokensRef: string | null) => {
  if (tokensRef === null) {
    sessionStorage.removeItem(STORAGE_KEYS.TOKENS_REF);
  } else {
    sessionStorage.setItem(STORAGE_KEYS.TOKENS_REF, tokensRef);
  }
};

export const setTokenExpiry = (expiry: number | null) => {
  if (expiry === null) {
    sessionStorage.removeItem(STORAGE_KEYS.TOKEN_EXPIRY);
  } else {
    sessionStorage.setItem(STORAGE_KEYS.TOKEN_EXPIRY, JSON.stringify(expiry));
  }
};

export const setAuthData = (tokensRef?: string, expiresIn?: number) => {
  if (tokensRef) {
    setTokensRef(tokensRef);
  }
  if (expiresIn) {
    setTokenExpiry(Date.now() + expiresIn * 1000);
  }
};

export const clearTokens = () => {
  setTokensRef(null);
  setTokenExpiry(null);
};
