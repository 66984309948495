import { tenantSelector } from '@/features/configuration/configurationSelector';
import { useTranslation } from '@/i18n';
import { useSelector } from '@/store/utils';
import { useEffect } from 'react';
import type { TransTextKeys } from '@/i18n/trans/text';

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const usePageTitle = (transKey: TransTextKeys) => {
  const { t, ready } = useTranslation();
  const tenant = useSelector(tenantSelector);

  useEffect(() => {
    if (!ready) {
      return;
    }

    document.title = `${capitalizeFirstLetter(tenant.value)} | ${t(`text.${transKey}`)}`;
  }, [t, ready, tenant, transKey]);
};
