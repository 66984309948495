import { Icons } from '@/components/icons';
import { Button } from '@/components/primitives/Button';
import { Checkbox } from '@/components/primitives/Checkbox';
import { Typography } from '@/components/primitives/Typography';
import { refundLoadingSelector } from '@/features/loading/loadingSelectors';
import { TransText } from '@/i18n/trans/text';
import { useDispatch, useSelector } from '@/store/utils';
import { breakpoints } from '@/utils/breakpoints';
import { cn } from '@/utils/cn';
import { type FC, useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { manageFlowBookingSelector } from '@/features/manage/manageSelectors';
import {
  confirmRefundOffer,
  deleteRefundOffer,
  getManageFlowBookingById,
  resetRefundSelection,
} from '@/features/manage/manageActions';
import sanitizeMarkup from '@/utils/sanitizeMarkup';
import { toast } from 'react-toastify';
import { consentsSelector } from '@/features/configuration/configurationSelector';
import RegularRefundModal from '@/components/manage/refund/RegularRefundModal';
import MobileRefundModal from '@/components/manage/refund/MobileRefundModal';

interface RefundModalProps {
  isOpen: boolean;
  refundSelection: FC;
  refundOverview: FC;
  selected: number;
  chooseToRefundText: 'chooseTripsToRefund' | 'chooseTravelPassesToRefund';
  refundText: 'refundTrips' | 'refundTravelPasses';
  onInitiateRefund: () => Promise<void>;
  onClose: () => void;
}

enum RefundStep {
  selection,
  confirmation,
}

const RefundModal: FC<RefundModalProps> = ({
  isOpen,
  refundSelection: RefundSelection,
  refundOverview: RefundOverview,
  selected,
  chooseToRefundText,
  refundText,
  onInitiateRefund,
  onClose,
}) => {
  const dispatch = useDispatch();
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const isLoading = useSelector(refundLoadingSelector);
  const [currentStep, setCurrentStep] = useState<RefundStep>(
    RefundStep.selection
  );
  const currentBooking = useSelector(manageFlowBookingSelector);
  const consents = useSelector(consentsSelector);
  const requiredConsents = useMemo(
    () =>
      consents?.items
        ?.filter(({ requiredForCustomerActions }) =>
          requiredForCustomerActions?.some(
            (action) => action.id === 'CUSTOMER_ACTION.TICKET_CANCELLATION'
          )
        )
        .map(({ id, description, name }) => ({
          id,
          text: description || name || '',
        })) || [],
    [consents?.items]
  );
  const [acceptedConsentsMap, setAcceptedConsentsMap] = useState<
    Record<number, boolean>
  >(requiredConsents.reduce((acc, c) => ({ ...acc, [c.id]: false }), {}));

  const handleClose = useCallback(() => {
    onClose();
    dispatch(resetRefundSelection());
    setCurrentStep(RefundStep.selection);
  }, [dispatch, onClose]);

  const handleOpenChange = useCallback(
    async (open: boolean) => {
      if (!open && currentStep === RefundStep.confirmation) {
        await dispatch(deleteRefundOffer()).unwrap();
      }

      handleClose();
    },
    [currentStep, dispatch, handleClose]
  );

  const handleBackToSelection = useCallback(async () => {
    await dispatch(deleteRefundOffer()).unwrap();
    setCurrentStep(RefundStep.selection);
  }, [dispatch]);

  const handleInitiateRefund = useCallback(async () => {
    await onInitiateRefund();
    setCurrentStep(RefundStep.confirmation);
  }, [onInitiateRefund]);

  const handleConfirmRefund = useCallback(async () => {
    await dispatch(confirmRefundOffer()).unwrap();
    await dispatch(getManageFlowBookingById(currentBooking?.id)).unwrap();

    toast.success(<TransText i18nKey="refundInitiated" />);
    handleClose();
  }, [currentBooking, dispatch, handleClose]);

  const renderFooter = useCallback(() => {
    return currentStep === RefundStep.selection ? (
      <div
        className={cn('flex gap-2', {
          'w-full': !isLaptopOrBigger,
        })}
      >
        <Button
          variant="tertiary"
          data-testid="cancel-refund-btn"
          onClick={handleClose}
          className="h-16 w-full laptop:h-10 laptop:w-fit"
        >
          <Typography variant={isLaptopOrBigger ? 'button' : 'subtitle'}>
            <TransText i18nKey="cancel" />
          </Typography>
        </Button>
        <Button
          data-testid="continue-refund-btn"
          onClick={handleInitiateRefund}
          disabled={!selected || isLoading}
          className="flex h-16 w-full laptop:h-10 laptop:w-fit"
        >
          <Typography variant={isLaptopOrBigger ? 'button' : 'subtitle'}>
            <TransText i18nKey="continue" />
          </Typography>
          {isLoading ? (
            <Icons.loader
              className="mr-2 animate-spin"
              height={isLaptopOrBigger ? 16 : 24}
              width={isLaptopOrBigger ? 16 : 24}
            />
          ) : (
            <Icons.arrowRight
              className="mr-2"
              height={isLaptopOrBigger ? 16 : 24}
              width={isLaptopOrBigger ? 16 : 24}
            />
          )}
        </Button>
      </div>
    ) : (
      <div className="flex w-full flex-col gap-2">
        <div className="flex flex-col justify-start gap-1">
          {requiredConsents.map((consent) => (
            <Checkbox
              key={consent.id}
              className="h-6 w-6"
              id={`consent-${consent.id}`}
              checked={acceptedConsentsMap[consent.id]}
              onCheckedChange={(checked) =>
                setAcceptedConsentsMap((prev) => ({
                  ...prev,
                  [consent.id]: checked === true,
                }))
              }
            >
              <Typography
                variant="body1"
                className="[&_a]:text-primary [&_a]:underline hover:[&_a]:text-primary-dark"
                dangerouslySetInnerHTML={{
                  __html: sanitizeMarkup(consent.text),
                }}
              />
            </Checkbox>
          ))}
        </div>
        <div
          className={cn('flex gap-2 laptop:justify-end', {
            'w-full': !isLaptopOrBigger,
          })}
        >
          <Button
            variant="tertiary"
            data-testid="back-btn"
            onClick={handleBackToSelection}
            className="w-full laptop:w-fit"
          >
            <Icons.arrowLeft height={16} width={16} />
            <TransText i18nKey="back" />
          </Button>
          <Button
            data-testid="confirm-refund-btn"
            onClick={handleConfirmRefund}
            disabled={
              isLoading || !Object.values(acceptedConsentsMap).every(Boolean)
            }
            className="flex w-full gap-1 laptop:w-fit"
          >
            {isLoading ? (
              <Icons.loader
                height={16}
                width={16}
                className="mr-2 animate-spin"
              />
            ) : (
              <Icons.ticketReturn height={16} width={16} />
            )}
            <TransText i18nKey="confirm" />
          </Button>
        </div>
      </div>
    );
  }, [
    acceptedConsentsMap,
    currentStep,
    handleBackToSelection,
    handleClose,
    handleConfirmRefund,
    handleInitiateRefund,
    isLaptopOrBigger,
    isLoading,
    requiredConsents,
    selected,
  ]);

  const title =
    currentStep === RefundStep.selection ? (
      <TransText i18nKey={chooseToRefundText} />
    ) : (
      <TransText i18nKey={refundText} values={{ count: selected }} />
    );

  const content =
    currentStep === RefundStep.selection ? (
      <RefundSelection />
    ) : (
      <RefundOverview />
    );

  return isLaptopOrBigger ? (
    <RegularRefundModal
      title={title}
      isOpen={isOpen}
      handleOpenChange={handleOpenChange}
      footer={renderFooter()}
    >
      {content}
    </RegularRefundModal>
  ) : (
    <MobileRefundModal
      title={title}
      isOpen={isOpen}
      handleOpenChange={handleOpenChange}
      footer={renderFooter()}
    >
      {content}
    </MobileRefundModal>
  );
};

export default RefundModal;
