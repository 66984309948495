import type { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import AccountMenu from '@/routes/account/AccountMenu';
import Divider from '@/components/primitives/Divider';
import { withAuthGuard } from '@/hoc/withAuthGuard';
import { usePageTitle } from '@/hooks/usePageTitle';

interface AccountProps {}

const AccountComponent: FC<AccountProps> = () => {
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const location = useLocation();
  const showOutlet = location.pathname !== '/account';
  const showMenu = isLaptopOrBigger || !showOutlet;
  usePageTitle('account');

  return (
    <div className="mx-auto flex w-full max-w-laptop flex-1 flex-col pt-10 laptop:flex-row laptop:pt-6">
      {showMenu && (
        <div className="flex w-full laptop:w-[30%]">
          <div className="flex w-full">
            <AccountMenu />
            {isLaptopOrBigger && <Divider orientation="vertical" />}
          </div>
        </div>
      )}
      {showOutlet && <Outlet />}
    </div>
  );
};

const Account = withAuthGuard('protected')(AccountComponent);

export default Account;
