import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/primitives/Dialog';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { useSelector } from '@/store/utils';
import { addAncillaryLoadingSelector } from '@/features/loading/loadingSelectors';
import { Loadable } from '@/components/Loadable';
import { TransText } from '@/i18n/trans/text';
import { Button } from '@/components/primitives/Button';

interface RegularAncillaryModalProps {
  children: ReactNode;
  isOverlayOpened: boolean;
  setIsOverlayOpened: Dispatch<SetStateAction<boolean>>;
}

const RegularAncillaryModal: FC<RegularAncillaryModalProps> = ({
  children,
  isOverlayOpened,
  setIsOverlayOpened,
}) => {
  const loading = useSelector(addAncillaryLoadingSelector);

  return (
    <Dialog open={isOverlayOpened} onOpenChange={setIsOverlayOpened}>
      <DialogTrigger asChild>
        <Button
          variant="secondary"
          size="medium"
          data-testid="add-new-ancillary-button-regular"
          className="rounded-md"
        >
          <Icons.addCircle height={16} width={16} />
          <TransText i18nKey="addNew" />
        </Button>
      </DialogTrigger>
      <DialogContent className="w-auto max-w-none">
        <DialogDescription className="sr-only">
          <TransText i18nKey="addExtras" />
        </DialogDescription>
        <Loadable loading={loading} overlay>
          <DialogHeader className="flex justify-between">
            <DialogTitle>
              <Typography variant="subtitle">
                <TransText i18nKey="addExtras" />
              </Typography>
            </DialogTitle>
          </DialogHeader>
          {children}
        </Loadable>
      </DialogContent>
    </Dialog>
  );
};

export default RegularAncillaryModal;
