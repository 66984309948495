import { type FC, useCallback, useEffect, useState } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { CardContent, CardPrimitiveRoot } from '@/components/primitives/Card';
import { Form } from '@/components/primitives/Form';
import { InputField } from '@/components/form/InputField';
import { Button } from '@/components/primitives/Button';
import { Icons } from '@/components/icons';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useTranslation } from 'react-i18next';
import { type FieldErrors, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Consents from '@/components/Consents';
import { toast } from 'react-toastify';
import type { ApiErrorResponse } from '@/types/api';
import { useNavigate } from 'react-router-dom';
import { createSignupSchema, type SignupValues } from '@/utils/zodSchema';
import Divider from '@/components/primitives/Divider';
import PasswordField from '@/components/form/PasswordField';
import { useAuth } from '@/hooks/useAuth';
import { useSelector } from '@/store/utils';
import { consentsSelector } from '@/features/configuration/configurationSelector';
import getApiInstance from '@/api';
import type { CustomerProfileParametersDto } from '@/types/dto';
import _pick from 'lodash/pick';
import { TransAlert } from '@/i18n/trans/alert';
import { PhoneInputField } from '@/components/form/PhoneInputField';
import { cn } from '@/utils/cn';

interface SignupFormProps {
  email: string;
}

const SignupForm: FC<SignupFormProps> = ({ email }) => {
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const consents = useSelector(consentsSelector);
  const [requirements, setRequirements] = useState<
    Pick<
      CustomerProfileParametersDto,
      'passwordComplexityRegexLocalization' | 'passwordComplexityRegex'
    >
  >({
    passwordComplexityRegex: '',
    passwordComplexityRegexLocalization: {
      languageId: '',
      description: '',
    },
  });

  useEffect(() => {
    async function getPasswordRequirements() {
      const api = (await getApiInstance()).agentApi;
      const { passwordComplexityRegexLocalization, passwordComplexityRegex } = (
        await api.CustomerProfilesConfiguration_GetCustomerProfilesParameters()
      ).data;

      setRequirements({
        passwordComplexityRegexLocalization,
        passwordComplexityRegex,
      });
    }

    getPasswordRequirements();
  }, []);

  const form = useForm<SignupValues>({
    mode: 'onSubmit',
    resolver: zodResolver(
      createSignupSchema(t, requirements.passwordComplexityRegex)
    ),
    defaultValues: {
      email,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      password: '',
      repeatPassword: '',
      consents:
        consents?.items?.map(({ id, isMandatory, description, code }) => ({
          isMandatory,
          description,
          checked: false,
          id,
          code,
        })) ?? [],
    },
  });

  const onSubmit = useCallback(
    async (values: SignupValues) => {
      setIsLoading(true);
      try {
        const registrationData = {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password!,
          consents: values.consents.map(({ code, checked }) => ({
            code,
            decisionId: checked
              ? 'CONSENT_DECISION.ACCEPTED'
              : 'CONSENT_DECISION.NOT_ACCEPTED',
          })),
          ...(values.phoneNumber && { phoneNumber: values.phoneNumber }),
        };
        await auth.registerWithPassword(registrationData);

        toast.success(<TransText i18nKey="accountCreated" />);
        navigate('/signup/confirmation', {
          replace: true,
          state: { id: values.email },
        });
      } catch (e: unknown) {
        const error = e as ApiErrorResponse;
        const reason = error.response?.data.errors?.[0]?.message;

        toast.error(
          <div className="flex gap-1">
            <TransText i18nKey="failedToCreateAccount" />
            {reason && <Typography>{reason}</Typography>}
          </div>
        );
      } finally {
        setIsLoading(false);
      }
    },
    [auth, navigate]
  );

  const validatePasswordFields = useCallback(
    () => form.trigger(['password', 'repeatPassword']),
    [form]
  );

  const onInvalid = useCallback((errors: FieldErrors<SignupValues>) => {
    if (Object.keys(_pick(errors, ['firstName', 'lastName', 'email'])).length) {
      toast.error(<TransAlert i18nKey="mandatoryFieldsMustBeFilled" />);
    }
    if (errors.consents) {
      toast.error(<TransAlert i18nKey="mustAgreeWithToC" />);
    }
  }, []);

  return (
    <div className="mx-auto flex w-full max-w-[410px] flex-col gap-8 p-4 tablet:mb-auto tablet:mt-auto laptop:p-0">
      <div className="flex items-center gap-4">
        <Typography variant={isLaptopOrBigger ? 'title' : 'mobile-title'}>
          <TransText i18nKey="hello" />
        </Typography>
      </div>
      <CardPrimitiveRoot className="rounded-xl">
        <CardContent className="flex flex-col">
          <div>
            <Typography variant={isLaptopOrBigger ? 'heading2' : 'subtitle'}>
              <TransText i18nKey="gladToHaveYouOnBoard" />
            </Typography>
            <Typography variant="body1" className="mt-3">
              <TransText i18nKey="fillOutFollowingRegistrationForm" />
            </Typography>
            <Divider className="my-4" />
          </div>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit, onInvalid)}
              className="flex flex-col gap-3"
            >
              <InputField
                control={form.control}
                className={isLaptopOrBigger ? 'h-8' : 'h-12'}
                name="email"
                label={<TransText i18nKey="email" />}
                required
                disabled
              />
              <InputField
                control={form.control}
                className={isLaptopOrBigger ? 'h-8' : 'h-12'}
                name="firstName"
                label={<TransText i18nKey="firstName" />}
                required
              />
              <InputField
                control={form.control}
                className={isLaptopOrBigger ? 'h-8' : 'h-12'}
                name="lastName"
                label={<TransText i18nKey="lastName" />}
                required
              />
              <PhoneInputField
                control={form.control}
                name="phoneNumber"
                className={cn('h-12 items-center', { 'h-8': isLaptopOrBigger })}
                label={<TransText i18nKey="phoneNumber" />}
              />
              <Divider />
              <div className="mb-3 flex flex-col gap-3">
                <Typography variant="body1-bold">
                  <TransText i18nKey="createAPassword" />
                </Typography>
                <Typography variant="body1">
                  {
                    requirements.passwordComplexityRegexLocalization
                      ?.description
                  }
                </Typography>
              </div>
              <PasswordField
                control={form.control}
                name="password"
                className={isLaptopOrBigger ? 'h-8' : 'h-12'}
                label={<TransText i18nKey="createNewPassword" />}
                onBlur={validatePasswordFields}
                required
              />
              <PasswordField
                control={form.control}
                name="repeatPassword"
                className={isLaptopOrBigger ? 'h-8' : 'h-12'}
                label={<TransText i18nKey="repeatNewPassword" />}
                onBlur={validatePasswordFields}
                required
              />
              <Divider />
              <Consents />
              <div className="pt-3">
                <Button
                  type="submit"
                  disabled={isLoading}
                  className="h-12 w-full"
                  data-testid="signup-form-submit"
                >
                  <Icons.check height={20} width={20} />
                  {isLoading && (
                    <Icons.loader
                      height={16}
                      width={16}
                      className="mr-2 animate-spin"
                    />
                  )}
                  <Typography variant="button">
                    <TransText i18nKey="confirm" />
                  </Typography>
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </CardPrimitiveRoot>
    </div>
  );
};

export default SignupForm;
