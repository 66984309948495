import { type FC } from 'react';
import { updateSelectedNonTripOffer } from '@/features/purchase/purchaseActions';
import { useDispatch } from '@/store/utils';
import type { NonTripOfferDTO } from '@/types/dto';
import BaseOfferCard from '@/components/purchase/card/BaseOfferCard';

interface NonTripOfferCardProps {
  nonTripOffer: NonTripOfferDTO;
  isSelected: boolean;
}

const NonTripOfferCard: FC<NonTripOfferCardProps> = ({
  nonTripOffer,
  isSelected,
}) => {
  const dispatch = useDispatch();
  const condition = nonTripOffer.admissionOfferParts?.[0].conditions?.[0];
  const minimalPrice = nonTripOffer.offerSummary?.minimalPrice;

  if (!nonTripOffer) {
    return null;
  }

  const handleSelect = () => {
    dispatch(updateSelectedNonTripOffer(nonTripOffer));
  };

  return (
    <BaseOfferCard
      title={condition?.shortDescription ?? condition?.description ?? ''}
      amount={minimalPrice?.amount || 0}
      isSelected={isSelected}
      onSelect={handleSelect}
      description={condition?.description}
      isRefundable={nonTripOffer.admissionOfferParts?.[0].refundable === 'YES'}
      isChangeable={
        nonTripOffer.admissionOfferParts?.[0].exchangeable === 'YES'
      }
      testId="select-non-trip-offer"
    />
  );
};

export default NonTripOfferCard;
