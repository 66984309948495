import { type FC, Fragment, type ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import OfferCard from '@/components/purchase/journey/OfferCard';
import { CardPrimitiveRoot } from '@/components/primitives/Card';
import type { JourneyDTO, LegDTO, OfferDTO } from '@/types/dto';
import type { ActionCreatorWithPayload, Selector } from '@reduxjs/toolkit';
import { Typography } from '@/components/primitives/Typography';
import StopDetails from '@/components/purchase/journey/StopDetails';
import type { RootState } from '@/store';
import { Loadable } from '@/components/Loadable';
import { useSelector } from '@/store/utils';
import { purchaseFlowBookingCreationLoadingSelector } from '@/features/loading/loadingSelectors';
import Footer from '@/components/Footer';
import { Button } from '@/components/primitives/Button';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import type { OfferMapByLegId, OfferMapItem } from '@/types/offer';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import _chunk from 'lodash/chunk';
import TransferTime from '@/components/manage/tickets/TransferTime';
import { getDurationBetweenTimestamps } from '@/utils/date-time';

interface JourneyDetailsProps {
  selectedJourney?: JourneyDTO;
  updateSelectedOfferMap: ActionCreatorWithPayload<OfferMapItem>;
  selectedOfferMapByLegIdSelector: Selector<RootState, OfferMapByLegId>;
  title: ReactNode;
  onContinue: () => void;
  onGoBack: () => void;
}

const JourneyDetails: FC<JourneyDetailsProps> = ({
  selectedJourney,
  updateSelectedOfferMap,
  selectedOfferMapByLegIdSelector,
  title,
  onContinue,
  onGoBack,
}) => {
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const loading = useSelector(purchaseFlowBookingCreationLoadingSelector);
  const legIds = useMemo(
    () =>
      selectedJourney?.trips?.reduce<Array<string>>((acc, { legs }) => {
        acc.push(
          ...(legs ?? [])
            .map((leg) => leg.id)
            .filter((item): item is string => Boolean(item))
        );

        return acc;
      }, []),
    [selectedJourney?.trips]
  );

  const getTripLegPart = useCallback(
    (legId?: string) => {
      if (!legId || !legIds || !legIds.length) return;

      return `${legIds.indexOf(legId) + 1}/${legIds.length}`;
    },
    [legIds]
  );

  const renderOfferCards = useCallback(
    (offers: Array<OfferDTO>, leg: LegDTO) => {
      return offers
        ?.filter(
          ({ coveredLegIds }) => leg.id && coveredLegIds?.includes(leg.id)
        )
        .map((offer, i) => (
          <OfferCard
            offer={offer}
            legId={leg.id}
            journeyDetails={{
              originStopName: leg.originStop?.name,
              destinationStopName: leg.destinationStop?.name,
            }}
            updateSelectedOfferMap={updateSelectedOfferMap}
            selectedOfferMapByLegIdSelector={selectedOfferMapByLegIdSelector}
            key={i}
          />
        ));
    },
    [selectedOfferMapByLegIdSelector, updateSelectedOfferMap]
  );

  // FIXME: Some cards throw invalid-gender-requirement error - NSW-1019
  return (
    <Loadable loading={loading} overlay>
      <div className="mx-auto flex w-full max-w-laptop flex-col p-0 pb-24 laptop:p-4 laptop:pb-24">
        <Typography
          asChild
          variant={isLaptopOrBigger ? 'heading1' : 'heading2'}
          className="p-4 laptop:p-0 laptop:pb-4"
        >
          <h1>{title}</h1>
        </Typography>
        <CardPrimitiveRoot
          className="rounded-none laptop:flex-none laptop:rounded-lg"
          data-testid="journey-details"
        >
          {selectedJourney?.trips?.map(({ offers, legs }) =>
            legs?.map((leg, key) => {
              const [firstHalf, secondHalf] = _chunk(
                offers,
                Math.ceil((offers?.length || 0) / 2)
              );

              const nextLeg = legs[key + 1];
              const transferTime = nextLeg
                ? getDurationBetweenTimestamps(
                    leg.arrivalTime,
                    nextLeg.departureTime
                  )
                : null;

              return (
                <Fragment key={key}>
                  <div className="flex flex-col gap-6 p-4 laptop:flex-row laptop:items-start">
                    <div className="laptop:sticky laptop:top-4 laptop:flex-shrink-0">
                      {legIds?.length && legIds.length > 1 && (
                        <Typography
                          variant="body1-bold"
                          className="mb-1 pl-1.5 text-primary"
                          asChild
                        >
                          <h2>
                            <TransText
                              i18nKey="tripLegPartIndex"
                              values={{ tripLegPart: getTripLegPart(leg.id) }}
                            />
                          </h2>
                        </Typography>
                      )}
                      <StopDetails leg={leg} />
                    </div>
                    {isLaptopOrBigger ? (
                      <div className="flex w-full flex-row gap-3">
                        <div className="flex w-full flex-col gap-3">
                          {renderOfferCards(firstHalf, leg)}
                        </div>
                        <div className="flex w-full flex-col gap-3">
                          {renderOfferCards(secondHalf, leg)}
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-4">
                        {offers && renderOfferCards(offers, leg)}
                      </div>
                    )}
                  </div>
                  {Boolean(transferTime) && (
                    <TransferTime
                      formattedTime={transferTime}
                      dividerVariant="medium"
                    />
                  )}
                </Fragment>
              );
            })
          )}
        </CardPrimitiveRoot>
      </div>
      <Footer
        actionButtons={
          <>
            <Button
              size="large"
              fullWidth
              data-testid="footer-back-button"
              className="rounded-lg laptop:h-11 laptop:w-auto"
              variant="tertiary"
              onClick={onGoBack}
            >
              <Icons.arrowLeft height={20} width={20} />
              <TransText i18nKey="back" />
            </Button>
            <Button
              size="large"
              fullWidth
              data-testid="footer-continue-button"
              className="rounded-lg laptop:h-11 laptop:w-auto"
              variant="cta"
              onClick={onContinue}
            >
              <TransText i18nKey="continue" />
              <Icons.arrowRight height={20} width={20} />
            </Button>
          </>
        }
      />
    </Loadable>
  );
};

export default JourneyDetails;
