import type { FC, ReactNode } from 'react';
import { Fragment } from 'react';
import type { JourneyDTO, SearchOffersDTO } from '@/types/dto';
import JourneyCard from '@/components/purchase/journey/JourneyCard';
import { Typography } from '@/components/primitives/Typography';
import type { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Loadable } from '@/components/Loadable';
import { getJourneyWarning, getWarningsByTripId } from '@/utils/warning';
import Footer from '@/components/Footer';
import { TotalPrice } from '@/components/purchase/TotalPrice';
import { Button } from '@/components/primitives/Button';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useSearchWidget } from '@/hooks/useSearchWidget';
import { formatDateLLL } from '@/utils/date-time';
import { useBackgroundTheme } from '@/hooks/useBackgroundTheme';
import { isLightBackground } from '@/utils/color';

interface JourneyListProps extends SearchOffersDTO {
  setSelectedJourney: ActionCreatorWithPayload<JourneyDTO>;
  onContinue: () => void;
  onGoBack?: () => void;
  title: ReactNode;
  loading: boolean;
}

const JourneyList: FC<JourneyListProps> = ({
  journeys,
  nextAvailableDepartureDate,
  warnings,
  onContinue,
  onGoBack,
  setSelectedJourney,
  title,
  loading,
}) => {
  const backgroundTheme = useBackgroundTheme();
  const isLight = isLightBackground(backgroundTheme);
  const warningsByTripId = getWarningsByTripId(warnings?.warnings);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const { setIsSearchOverlayOpened, updateAndSubmit } = useSearchWidget();

  return (
    <Loadable loading={loading} className={{ regular: 'mt-6' }}>
      <div className="mx-auto w-full max-w-laptop p-4">
        {journeys ? (
          <div className="mx-auto max-w-mobile" data-testid="journey-list">
            <Typography
              asChild
              variant={isLaptopOrBigger ? 'heading1' : 'heading2'}
              className="mb-4"
            >
              <h1>{title}</h1>
            </Typography>
            {journeys.map((journey, i) => (
              <Fragment key={i}>
                <JourneyCard
                  journey={journey}
                  warningMessage={getJourneyWarning(journey, warningsByTripId)}
                  onContinue={onContinue}
                  setSelectedJourney={setSelectedJourney}
                />
              </Fragment>
            ))}
          </div>
        ) : (
          <div className="mx-auto w-fit py-8">
            {nextAvailableDepartureDate ? (
              <>
                <Typography
                  className={isLight ? 'text-neutral' : 'text-neutral-light'}
                  variant="paragraph"
                  data-testid="no-trips-found-message"
                >
                  <TransText i18nKey="noTripsFoundNextDate" />.
                </Typography>
                <Button
                  data-testid="next-avaialable-date-btn"
                  variant="secondary"
                  size="large"
                  className="mt-8"
                  fullWidth
                  onClick={() =>
                    updateAndSubmit({
                      departureDate: new Date(nextAvailableDepartureDate),
                    })
                  }
                >
                  <TransText
                    i18nKey="nextAvailableDate"
                    values={{
                      date: formatDateLLL(new Date(nextAvailableDepartureDate)),
                    }}
                  />
                  <Icons.arrowRight height={20} width={20} />
                </Button>
                {!isLaptopOrBigger && (
                  <Button
                    size="large"
                    fullWidth
                    variant="secondary"
                    data-testid="edit-search-params-btn"
                    className="mt-3"
                    onClick={() => setIsSearchOverlayOpened(true)}
                  >
                    <Icons.search height={20} width={20} />
                    <TransText i18nKey="editSearch" />
                  </Button>
                )}
              </>
            ) : (
              <>
                <Typography
                  variant="paragraph"
                  data-testid="next-available-departure-date"
                  className={isLight ? 'text-neutral' : 'text-neutral-light'}
                >
                  <TransText i18nKey="changeSearchInputs" />
                </Typography>
                {!isLaptopOrBigger && (
                  <Button
                    fullWidth
                    size="large"
                    onClick={() => setIsSearchOverlayOpened(true)}
                    variant="secondary"
                    data-testid="edit-search-params-btn"
                    className="mt-6"
                  >
                    <Icons.search height={20} width={20} />
                    <TransText i18nKey="editSearch" />
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {onGoBack && (
        <Footer
          content={<TotalPrice />}
          actionButtons={
            <>
              <Button
                size="large"
                fullWidth
                data-testid="footer-back-button"
                className="rounded-lg laptop:h-11 laptop:w-auto"
                variant="tertiary"
                onClick={onGoBack}
              >
                <Icons.arrowLeft />
                <Typography variant="subtitle">
                  <TransText i18nKey="back" />
                </Typography>
              </Button>
              <Button
                size="large"
                fullWidth
                data-testid="footer-continue-button"
                className="rounded-lg laptop:h-11 laptop:w-auto"
                onClick={onContinue}
                variant="cta"
                disabled
              >
                <Typography variant="subtitle" className="self-center">
                  <TransText i18nKey="continue" />
                </Typography>
                <Icons.arrowRight />
              </Button>
            </>
          }
        />
      )}
    </Loadable>
  );
};

export default JourneyList;
