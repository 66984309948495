import type { TransKeys } from '@/i18n/trans/index';
import { createTrans } from '@/i18n/trans/index';
import { Trans } from 'react-i18next';
import type { FC } from 'react';
import { useSelector } from '@/store/utils';
import { tenantSelector } from '@/features/configuration/configurationSelector';

const TenantCopies = createTrans({
  'turnit.successMessage': (
    <Trans i18nKey="tenant.turnit.successMessage" defaults="Success!" />
  ),
  'arenaways.successMessage': (
    <Trans
      i18nKey="tenant.arenaways.successMessage"
      defaults="Let this journey begin, Arenaways style!"
    />
  ),
  'turnit.travelPassDisclaimer': <></>,
  'arenaways.travelPassDisclaimer': (
    <Trans
      i18nKey="tenant.arenaways.travelPassDisclaimer"
      defaults="<0>Click here</0> for integrated TRAIN+BUS travel passes"
      components={[
        <a
          href="https://www.autobus.it/arenaways-to/TPLWebPortal/it/showcase"
          target="_blank"
        />,
      ]}
    />
  ),
});

export const TransTenantSpecific: FC<{ i18nKey: TransTenantSpecificKeys }> = ({
  i18nKey,
}) => {
  const { value: tenant } = useSelector(tenantSelector);

  return <TenantCopies i18nKey={`${tenant}.${i18nKey}`} />;
};

type TenantCopiesKeys = TransKeys<typeof TenantCopies>;
type TransKeySuffix<S> = S extends `${string}.${infer Suffix}` ? Suffix : never;

export type TransTenantSpecificKeys = TransKeySuffix<TenantCopiesKeys>;
