import { type FC, useCallback, useState } from 'react';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import { Typography } from '@/components/primitives/Typography';
import { useDispatch, useSelector } from '@/store/utils';
import { Button } from '@/components/primitives/Button';
import useBookingSummaryData from '@/hooks/useBookingSummaryData';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import RegularBookingSummary from '@/components/booking-summary/RegularBookingSummary';
import MobileBookingSummary from '@/components/booking-summary/MobileBookingSummary';
import { useBookingRefundEligibleTrips } from '@/hooks/useBookingRefundEligibleTrips';
import { manageFlowBookingSelector } from '@/features/manage/manageSelectors';
import { Flow } from '@/types/booking';
import { clearManageFlowBooking } from '@/features/manage/manageActions';
import Footer from '@/components/Footer';
import Tickets from '@/components/manage/tickets/Tickets';
import TravelPasses from '@/components/manage/travel-passes/TravelPasses';
import Divider from '@/components/primitives/Divider';
import { useTranslation } from '@/i18n';
import RefundLegModal from '@/components/manage/refund/leg/RefundLegModal';
import RefundNonTripModal from '@/components/manage/refund/non-trip-offer/RefundNonTripModal';
import { useBookingNonTripOfferDetails } from '@/hooks/useBookingNonTripOfferDetails';
import { useBookingNonTripOfferIssuers } from '@/hooks/useBookingNonTripOfferIssuers';
import { cn } from '@/utils/cn';

interface BookingDetails {}

export const BookingDetails: FC<BookingDetails> = () => {
  const dispatch = useDispatch();
  const booking = useSelector(manageFlowBookingSelector);
  const summaryData = useBookingSummaryData(booking, [
    'CONFIRMED',
    'EXCHANGED',
    'REFUNDED',
    'FULFILLED',
  ]);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const { refundEligibleOutboundTrips, refundEligibleInboundTrips } =
    useBookingRefundEligibleTrips(booking);
  const { travelPass, multiRide } = useBookingNonTripOfferDetails(
    Flow.manageBooking
  );
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const { t } = useTranslation();
  const areEligibleTripsRefundable = Boolean(
    refundEligibleOutboundTrips.length || refundEligibleInboundTrips.length
  );
  const areEligibleNonTripOffersRefundable = Boolean(
    [...travelPass, ...multiRide].filter(
      ({ status, refundable }) => status !== 'REFUNDED' && refundable === 'YES'
    ).length
  );
  const isRefundButtonVisible =
    areEligibleTripsRefundable || areEligibleNonTripOffersRefundable;

  const handleGoBack = useCallback(() => {
    dispatch(clearManageFlowBooking());
  }, [dispatch]);

  useBookingNonTripOfferIssuers(Flow.manageBooking);

  return (
    <div
      className={cn('pb-16 laptop:pb-4', {
        'pb-36 laptop:pb-20': isRefundButtonVisible,
      })}
    >
      <div className="mx-auto flex max-w-laptop items-center gap-2 p-4">
        <Button
          data-testid="go-back-button"
          className="-ml-2 p-2"
          variant="icon"
          aria-label={t('text.goBack')}
          onClick={handleGoBack}
        >
          <Icons.arrowLeft />
        </Button>

        <div className="flex items-baseline gap-1">
          <Typography variant={isLaptopOrBigger ? 'heading1-light' : 'body1'}>
            <>
              <TransText i18nKey="booking" />
              &#58;
            </>
          </Typography>
          <Typography variant={isLaptopOrBigger ? 'heading1' : 'subtitle'}>
            {booking?.code}
          </Typography>
        </div>
      </div>
      <Divider />
      <div className="mx-auto mt-8 flex max-w-laptop flex-col px-4 laptop:flex-row laptop:gap-10">
        {booking?.bookedTrips?.length ? <Tickets /> : <TravelPasses />}
        {isLaptopOrBigger && (
          <div className="flex flex-col gap-7">
            <Typography variant="heading2" asChild>
              <h1>
                <TransText i18nKey="bookingSummary" />
              </h1>
            </Typography>
            <RegularBookingSummary {...summaryData} flow={Flow.manageBooking} />
          </div>
        )}
      </div>
      <Footer
        content={
          !isLaptopOrBigger && (
            <MobileBookingSummary {...summaryData} flow={Flow.manageBooking} />
          )
        }
        actionButtons={
          isRefundButtonVisible && (
            <Button
              fullWidth
              size="regular"
              variant="secondary"
              data-testid="refund-button"
              className="h-16 rounded-lg laptop:h-10 laptop:w-auto"
              onClick={() => setIsRefundModalOpen(true)}
            >
              <Icons.ticketReturn />
              <Typography variant="button">
                <TransText i18nKey="refund" />
              </Typography>
            </Button>
          )
        }
      />
      <RefundLegModal
        isOpen={areEligibleTripsRefundable && isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
      />
      <RefundNonTripModal
        isOpen={areEligibleNonTripOffersRefundable && isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(false)}
      />
    </div>
  );
};
