import { CardPrimitiveRoot } from '@/components/primitives/Card';
import { Icons } from '@/components/icons';
import { Loadable } from '@/components/Loadable';
import { Button } from '@/components/primitives/Button';
import { Typography } from '@/components/primitives/Typography';
import Footer from '@/components/Footer';
import NonTripOfferCard from '@/components/purchase/NonTripOfferCard';
import {
  nonTripOffersLoadingSelector,
  purchaseFlowBookingCreationLoadingSelector,
} from '@/features/loading/loadingSelectors';
import { TransText } from '@/i18n/trans/text';
import { useSelector } from '@/store/utils';
import type { NonTripOfferDTO, SearchNonTripOffersDTO } from '@/types/dto';
import type { FC } from 'react';
import { breakpoints } from '@/utils/breakpoints';
import { useMediaQuery } from 'react-responsive';
import _chunk from 'lodash/chunk';
import { TransTenantSpecific } from '@/i18n/trans/tenantSpecific';
import { tenantSelector } from '@/features/configuration/configurationSelector';
import { cn } from '@/utils/cn';
import { getTenantConfiguration } from '@/utils/tenant';

interface NonTripOfferListProps extends SearchNonTripOffersDTO {
  selectedNonTripOffer?: NonTripOfferDTO;
  onContinue: () => void;
}

const NonTripOfferList: FC<NonTripOfferListProps> = ({
  nonTripOffers,
  selectedNonTripOffer,
  onContinue,
}) => {
  const tenant = useSelector(tenantSelector);
  const configuration = getTenantConfiguration(tenant.value);
  const loadingNonTripOffers = useSelector(nonTripOffersLoadingSelector);
  const loadingBooking = useSelector(
    purchaseFlowBookingCreationLoadingSelector
  );
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const [firstHalf, secondHalf] = _chunk(
    nonTripOffers,
    Math.ceil((nonTripOffers?.length || 0) / 2)
  );

  return (
    <Loadable
      loading={loadingNonTripOffers || loadingBooking}
      className={{ regular: 'mt-6' }}
    >
      <div className="mx-auto w-full max-w-[800px] p-0 pb-24 laptop:p-4 laptop:pb-24">
        <Typography
          asChild
          variant={isLaptopOrBigger ? 'heading1' : 'heading2'}
          className="p-4 laptop:p-0 laptop:pb-4"
        >
          <h1>{<TransText i18nKey="selectTravelPass" />}</h1>
        </Typography>

        {nonTripOffers?.length ? (
          <>
            <CardPrimitiveRoot
              className={cn(
                'flex flex-col gap-4 rounded-none border-none p-4 shadow-none laptop:flex-none laptop:flex-grow laptop:flex-row laptop:rounded-lg laptop:border-solid laptop:shadow-sm'
              )}
            >
              {nonTripOffers?.length ? (
                isLaptopOrBigger ? (
                  <>
                    <div className="flex w-full flex-col gap-3">
                      {firstHalf?.map((nonTripOffer, index) => (
                        <NonTripOfferCard
                          key={nonTripOffer.id ?? index}
                          nonTripOffer={nonTripOffer}
                          isSelected={Boolean(
                            selectedNonTripOffer?.id === nonTripOffer.id
                          )}
                        />
                      ))}
                    </div>
                    <div className="flex w-full flex-col gap-3">
                      {secondHalf?.map((nonTripOffer, index) => (
                        <NonTripOfferCard
                          key={nonTripOffer.id ?? index}
                          nonTripOffer={nonTripOffer}
                          isSelected={Boolean(
                            selectedNonTripOffer?.id === nonTripOffer.id
                          )}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  nonTripOffers.map((nonTripOffer, index) => (
                    <NonTripOfferCard
                      key={nonTripOffer.id ?? index}
                      nonTripOffer={nonTripOffer}
                      isSelected={Boolean(
                        selectedNonTripOffer?.id === nonTripOffer.id
                      )}
                    />
                  ))
                )
              ) : (
                <Typography data-testid="no-trips-found-message">
                  <TransText i18nKey="noTravelPassesFound" />.
                </Typography>
              )}
            </CardPrimitiveRoot>
            {configuration?.showTravelPassDisclaimer && (
              <div className="prose flex items-center gap-1.5 px-4 pb-2 prose-p:m-0 prose-a:text-blue-600 prose-a:no-underline laptop:px-0 laptop:pb-0 laptop:pt-4">
                <Icons.infoCircle />
                <Typography variant="body2" className="text-center">
                  <TransTenantSpecific i18nKey="travelPassDisclaimer" />
                </Typography>
              </div>
            )}
          </>
        ) : (
          <Typography
            data-testid="no-trips-found-message"
            className="px-4 laptop:px-0"
          >
            <TransText i18nKey="noTravelPassesFound" />.
          </Typography>
        )}
      </div>
      <Footer
        actionButtons={
          <Button
            fullWidth
            size="large"
            data-testid="footer-continue-button"
            className="rounded-lg laptop:h-11 laptop:w-auto"
            disabled={!selectedNonTripOffer}
            onClick={onContinue}
            variant="cta"
          >
            <Typography variant="subtitle" className="self-center">
              <TransText i18nKey="continue" />
            </Typography>
            <Icons.arrowRight />
          </Button>
        }
      />
    </Loadable>
  );
};

export default NonTripOfferList;
