import type { I18nLanguage } from '@/i18n';
import type { Country } from 'react-phone-number-input';
import type { TenantOption } from '@/types/tenant';
import type { StopsSearchingStrategy } from '@turnit-ride-ui/webshop-search-widget/widget/types/stop';

export const TENANT_OPTIONS = [
  {
    value: 'turnit',
    label: 'Turnit',
  },
  {
    value: 'arenaways',
    label: 'Arenaways',
  },
] as const;

export const getTenantConfiguration = (
  tenant: TenantOption['value'] | string
):
  | {
      language: I18nLanguage;
      defaultCountry: Country;
      webPageUrl?: string;
      showTravelPassDisclaimer: boolean;
      showSuccessMessage: boolean;
      showSeatSelection: boolean;
      showPayWithExternalPayment: boolean;
      showPayWithAdyen: boolean;
      stopsSearchingStrategy: StopsSearchingStrategy;
    }
  | undefined => {
  switch (tenant) {
    case 'turnit':
      return {
        language: 'en',
        defaultCountry: 'EE',
        showTravelPassDisclaimer: false,
        showSuccessMessage: true,
        showSeatSelection: true,
        showPayWithExternalPayment: true,
        showPayWithAdyen: false,
        stopsSearchingStrategy: 'dynamic',
      };
    case 'arenaways':
      return {
        language: 'it',
        defaultCountry: 'IT',
        webPageUrl: 'https://arenaways.it/',
        showTravelPassDisclaimer: true,
        showSuccessMessage: true,
        showSeatSelection: false,
        showPayWithExternalPayment: false, // DO NOT ENABLE FOR LIVE CUSTOMER
        showPayWithAdyen: true,
        stopsSearchingStrategy: 'static',
      };
    default:
      return;
  }
};
