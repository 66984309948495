import { getTokensRef } from '@/utils/auth';
import type { InternalAxiosRequestConfig } from 'axios';

/**
 * Takes the access_token from session storage and injects it into requests
 * as the Authorization header
 */
export default function authorizationMiddleware(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  const tokensRef = getTokensRef();

  if (tokensRef) {
    config.headers['Authorization-Reference'] = tokensRef;
  }

  return config;
}
