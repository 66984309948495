import type { FC } from 'react';
import { Icons } from '@/components/icons';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/primitives/Dialog';
import { TransText } from '@/i18n/trans/text';
import LanguageSelectContent from '@/components/header/language-select/LanguageSelectContent';
import { Button } from '@/components/primitives/Button';

interface MobileLanguageSelectProps {}

const MobileLanguageSelect: FC<MobileLanguageSelectProps> = () => {
  return (
    <Dialog>
      <DialogTrigger className="flex items-center px-0" asChild>
        <Button variant="ghostLink" data-testid="language-select-trigger">
          <Icons.globe />
        </Button>
      </DialogTrigger>
      <DialogContent className="w-5/6 tablet:w-[480px]">
        <DialogHeader className="border-none p-4 text-left">
          <DialogTitle>
            <TransText i18nKey="selectLanguage" />
          </DialogTitle>
          <DialogDescription hidden>
            <TransText i18nKey="selectLanguage" />
          </DialogDescription>
        </DialogHeader>
        <LanguageSelectContent />
      </DialogContent>
    </Dialog>
  );
};

export default MobileLanguageSelect;
