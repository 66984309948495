import { cn } from '@/utils/cn';
import type { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

export interface LoadableProps {
  loading: boolean;
  children: ReactNode;
  overlay?: boolean;
  className?: { regular?: string; overlay?: string };
}

export const Loadable: FC<LoadableProps> = ({
  loading,
  children,
  overlay,
  className,
}) => {
  if (overlay) {
    return (
      <>
        {loading &&
          createPortal(
            <>
              <div
                className="pointer-events-auto fixed inset-0 z-[51] flex items-center justify-center"
                data-testid="overlay-spinner-wrapper"
              >
                <Spinner />
              </div>
              <div className="pointer-events-auto fixed inset-0 z-[51] bg-gray-500 opacity-50" />
            </>,
            document.body
          )}
        {children}
      </>
    );
  }

  return loading ? (
    <div className={cn('flex items-center justify-center', className?.regular)}>
      <Spinner />
    </div>
  ) : (
    children
  );
};

const Spinner = () => (
  <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-primary" />
);
