import * as React from 'react';
import { cn } from '@/utils/cn';
import { useThemedColors } from '@/hooks/useThemedColors';
import { cva, type VariantProps } from 'class-variance-authority';

const dividerVariants = cva('max-w-full', {
  variants: {
    orientation: {
      horizontal: 'h-[1px] min-h-[1px] w-full',
      vertical: 'h-full min-w-[1px] border-l',
    },
    grow: {
      true: 'flex-1',
      false: 'flex-shrink-0',
    },
    variant: {
      regular: '',
      medium: '',
      strong: '',
    },
    dashed: {
      true: '',
      false: 'border-t',
    },
  },
  defaultVariants: {
    orientation: 'horizontal',
    grow: true,
    variant: 'regular',
    dashed: false,
  },
});

export type DividerVariant = NonNullable<
  VariantProps<typeof dividerVariants>['variant']
>;

interface DividerProps
  extends Omit<VariantProps<typeof dividerVariants>, 'variant'> {
  className?: string;
  variant?: DividerVariant;
  dashLength?: number;
}

const Divider = React.forwardRef<HTMLDivElement, DividerProps>(
  (
    {
      className,
      orientation = 'horizontal',
      grow = true,
      variant = 'regular',
      dashed = false,
      dashLength = 8,
      ...props
    },
    ref
  ) => {
    const { getDividerColors } = useThemedColors();
    const borderColorClass = getDividerColors(variant);
    const colorClass = borderColorClass.replace('border-', 'text-');

    const dashedStyles = dashed
      ? `bg-[linear-gradient(to_right,currentColor_50%,transparent_50%)] bg-[length:${dashLength}px_1px] bg-left-top bg-repeat-x border-none`
      : '';

    return (
      <div
        ref={ref}
        className={cn(
          dividerVariants({ orientation, grow, variant, dashed: false }),
          !dashed && borderColorClass,
          dashed && [colorClass, dashedStyles],
          className
        )}
        {...props}
      />
    );
  }
);

Divider.displayName = 'Divider';

export { Divider };
export default Divider;
