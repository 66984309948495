import React from 'react';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';

type PriceDisplayProps = {
  amount?: number;
};

const PriceDisplay: React.FC<PriceDisplayProps> = ({ amount = 0 }) => {
  const configuredCurrency = useSelector(currencySelector);
  const formattedPrice = amount.toFixed(2);

  return (
    <span>
      {formattedPrice} {configuredCurrency?.symbol}
    </span>
  );
};

export default PriceDisplay;
