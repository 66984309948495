import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import Changeability from '@/components/purchase/card/Changeability';
import Refundability from '@/components/purchase/card/Refundability';
import PriceDisplay from '@/components/PriceDisplay';

interface ProductDetailsProps {
  passengerCount: number;
  description: string | undefined;
  admissionsAmount: number;
  areAllRefunded: boolean;
  areAllRefundable: boolean;
  areAllExchangeable: boolean;
}

const ProductDetails: FC<ProductDetailsProps> = ({
  passengerCount,
  description,
  admissionsAmount,
  areAllRefunded,
  areAllRefundable,
  areAllExchangeable,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between laptop:items-end laptop:pb-0">
        <div className="flex items-center gap-2">
          <Typography variant="body1-bold">{description}</Typography>
          <Typography variant="body2" className="text-neutral">
            <TransText
              i18nKey="passengersCount"
              values={{ count: passengerCount }}
            />
          </Typography>
        </div>
        {!areAllRefunded && (
          <Typography variant="body1-bold" className="text-primary">
            <PriceDisplay amount={admissionsAmount} />
          </Typography>
        )}
      </div>
      <div className="flex gap-3">
        <Changeability condition={areAllExchangeable} />
        <Refundability condition={areAllRefundable} />
      </div>
    </div>
  );
};

export default ProductDetails;
