import type { FC, ReactNode } from 'react';

type RegularSearchWidgetProps = {
  children: ReactNode;
};

export const RegularSearchWidget: FC<RegularSearchWidgetProps> = ({
  children,
}) => {
  // should work without pb-6, but since widget's input heights aren't properly applied, it acts weird now
  return <div className="mx-auto w-full max-w-laptop p-4 pb-6">{children}</div>;
};
