import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useTicketDownload } from '@/hooks/useTicketDownload';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import Divider from '@/components/primitives/Divider';
import { Button } from '@/components/primitives/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '@/store/utils';
import { Loadable } from '@/components/Loadable';
import { purchaseFlowBookingFetchingLoadingSelector } from '@/features/loading/loadingSelectors';
import { usePkPassDownload } from '@/hooks/usePkPassDownload';
import { sendPurchaseConfirmation } from '@/features/purchase/purchaseService';
import { toast } from 'react-toastify';
import { STORAGE_KEYS, useStorage } from '@/hooks/useStorage';
import type { PurchaseFlowBookingStorageData } from '@/types/storage';
import { CenteredLayout } from '@/components/layouts/CenteredLayout';
import { TransTenantSpecific } from '@/i18n/trans/tenantSpecific';
import { getTenantConfiguration } from '@/utils/tenant';
import { tenantSelector } from '@/features/configuration/configurationSelector';

const Success = () => {
  const navigate = useNavigate();
  const tenant = useSelector(tenantSelector);
  const configuration = getTenantConfiguration(tenant.value);
  const loading = useSelector(purchaseFlowBookingFetchingLoadingSelector);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPkPassDownloading, setIsPkPassDownloading] = useState(false);
  const { value: successData, removeValue } =
    useStorage<PurchaseFlowBookingStorageData>(
      sessionStorage,
      STORAGE_KEYS.CHECKOUT_SUCCESS_DATA
    );

  const handleTicketDownload = useTicketDownload({
    bookingId: successData?.bookingId,
    onStart: () => setIsDownloading(true),
    onFinish: () => setIsDownloading(false),
    bookingNumber: successData?.code,
  });

  const handlePkPassDownload = usePkPassDownload({
    fulfillmentIds: successData?.fulfillmentIds,
    onStart: () => setIsPkPassDownloading(true),
    onFinish: () => setIsPkPassDownloading(false),
    bookingNumber: successData?.code,
  });

  useEffect(() => {
    if (!successData?.bookingId || !successData?.payerEmail) {
      navigate('/', { replace: true });
      return;
    }

    sendPurchaseConfirmation(successData.bookingId, [
      successData.payerEmail,
    ]).catch(() => {
      toast.error(<TransText i18nKey="errorSendingEmail" />);
    });

    return () => {
      removeValue();
    };
  }, [navigate, removeValue, successData]);

  if (!successData?.bookingId) {
    return null;
  }

  return (
    <Loadable loading={loading}>
      <CenteredLayout>
        <div className="mx-auto mt-10 flex w-full max-w-tablet items-center p-4">
          <div className="grid w-full grid-cols-1 gap-2 laptop:grid-cols-2 laptop:gap-10">
            <div className="flex flex-col">
              <div className="flex items-center gap-3">
                <Icons.checkCircle height={28} width={28} />
                <Typography
                  variant={isLaptopOrBigger ? 'title' : 'mobile-title'}
                  asChild
                >
                  <h1>
                    <TransText i18nKey="ticketPurchased" />
                  </h1>
                </Typography>
              </div>
              <Typography variant="paragraph" className="mt-2">
                <TransText
                  i18nKey="bookingNo"
                  values={{ number: successData.code }}
                />
              </Typography>
              <Divider className="mb-6 mt-4" />
              {configuration?.showSuccessMessage && (
                <Typography variant="paragraph">
                  <TransTenantSpecific i18nKey="successMessage" />
                </Typography>
              )}
            </div>

            <div className="mt-4 laptop:mt-0">
              <div className="flex flex-col gap-4 py-3 tablet:flex-row tablet:items-center">
                <Button
                  fullWidth
                  className="laptop:w-fit"
                  data-testid="download-pdf-button"
                  variant="tertiary"
                  size="medium"
                  onClick={handleTicketDownload}
                  disabled={isDownloading || !successData.bookingId}
                >
                  {isDownloading ? (
                    <Icons.loader
                      height={16}
                      width={16}
                      className="animate-spin"
                    />
                  ) : (
                    <Icons.download height={16} width={16} />
                  )}
                  <TransText i18nKey="downloadPdf" />
                </Button>
                <Button
                  fullWidth
                  size="medium"
                  className="laptop:w-fit"
                  data-testid="download-pkpass-button"
                  variant="tertiary"
                  onClick={handlePkPassDownload}
                  disabled={isPkPassDownloading || !successData.bookingId}
                >
                  {isPkPassDownloading ? (
                    <Icons.loader
                      height={16}
                      width={16}
                      className="animate-spin"
                    />
                  ) : (
                    <Icons.file height={16} width={16} />
                  )}
                  <TransText i18nKey="downloadPkpass" />
                </Button>
              </div>
              <Divider className="mb-6 mt-4" />
              <Typography variant="paragraph">
                <TransText i18nKey="ticketSent" />
              </Typography>
            </div>
          </div>
        </div>
      </CenteredLayout>
    </Loadable>
  );
};

export default Success;
