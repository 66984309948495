import { type FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from '@/i18n';
import { createLoginSchema, type LoginValues } from '@/utils/zodSchema';
import { Loadable } from '@/components/Loadable';
import { Typography } from '@/components/primitives/Typography';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { TransText } from '@/i18n/trans/text';
import { CardContent, CardPrimitiveRoot } from '@/components/primitives/Card';
import { Form } from '@/components/primitives/Form';
import { InputField } from '@/components/form/InputField';
import { Button } from '@/components/primitives/Button';
import { toast } from 'react-toastify';
import { useAuth } from '@/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import PasswordField from '@/components/form/PasswordField';
import { CenteredLayout } from '@/components/layouts/CenteredLayout';
import { withAuthGuard } from '@/hoc/withAuthGuard';
import { isLightBackground } from '@/utils/color';
import { useBackgroundTheme } from '@/hooks/useBackgroundTheme';
import { cn } from '@/utils/cn';
import { usePageTitle } from '@/hooks/usePageTitle';

interface LoginProps {}

const LoginComponent: FC<LoginProps> = () => {
  const backgroundTheme = useBackgroundTheme();
  const isLight = isLightBackground(backgroundTheme);
  const { t } = useTranslation();
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const [loading, setLoading] = useState(false);
  const { loginWithPassword } = useAuth();
  const navigate = useNavigate();
  usePageTitle('logIn');

  const form = useForm<LoginValues>({
    mode: 'onBlur',
    resolver: zodResolver(createLoginSchema(t)),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = useCallback(
    async (values: LoginValues) => {
      setLoading(true);
      try {
        await loginWithPassword(values);
        navigate('/account', { replace: true });
      } catch (error) {
        toast.error(
          <Typography variant="body1">
            <TransText i18nKey="loginFailed" />
            {error instanceof Error ? `: ${error.message}` : ''}
          </Typography>
        );
      } finally {
        setLoading(false);
      }
    },
    [loginWithPassword, navigate]
  );

  return (
    <CenteredLayout>
      <div className="mx-auto flex w-full max-w-[410px] flex-col gap-8 p-4 tablet:mb-auto tablet:mt-auto laptop:p-0">
        <Typography variant={isLaptopOrBigger ? 'title' : 'mobile-title'}>
          <TransText i18nKey="logIn" />
        </Typography>
        <Loadable loading={loading} overlay>
          <CardPrimitiveRoot className="rounded-xl">
            <CardContent className="flex flex-col gap-6">
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="flex flex-col gap-3"
                >
                  <InputField
                    control={form.control}
                    name="email"
                    className={isLaptopOrBigger ? 'h-8' : 'h-12'}
                    required
                    placeholder={t('text.enterYourEmailAddress')}
                    label={<TransText i18nKey="email" />}
                  />
                  <PasswordField
                    control={form.control}
                    name="password"
                    className={isLaptopOrBigger ? 'h-8' : 'h-12'}
                    required
                    placeholder={t('text.enterYourPassword')}
                    label={<TransText i18nKey="password" />}
                  />
                  <Button
                    data-testid="login-submit-button"
                    type="submit"
                    className="mt-3 h-12 w-full"
                  >
                    <Typography variant="button">
                      <TransText i18nKey="logIn" />
                    </Typography>
                  </Button>
                </form>
              </Form>
              <Button
                data-testid="forgot-password-button"
                onClick={() => console.log('Forgot password?')}
                variant="ghost"
                className="self-center"
              >
                <Typography variant="button">
                  <TransText i18nKey="forgotPassword" />
                </Typography>
              </Button>
            </CardContent>
          </CardPrimitiveRoot>
        </Loadable>
        <div className="flex items-center gap-3 self-center">
          <Typography variant="body1">
            <TransText i18nKey="notRegisteredYet" />
          </Typography>
          <Button
            variant="ghost"
            data-testid="create-an-account-button"
            className={cn('p-0', isLight ? 'text-primary' : 'text-accent')}
            onClick={() => navigate('/signup')}
          >
            <Typography variant="button">
              <TransText i18nKey="createAnAccount" />
            </Typography>
          </Button>
        </div>
      </div>
    </CenteredLayout>
  );
};

const Login = withAuthGuard('loginOrSignup')(LoginComponent);

export default Login;
