import React, { type Dispatch, type FC, type SetStateAction } from 'react';
import { Button } from '@/components/primitives/Button';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { Icons } from '@/components/icons';
import { useFormContext } from 'react-hook-form';
import type { AncillaryValues } from '@/utils/zodSchema';
import Divider from '@/components/primitives/Divider';
import PriceDisplay from '@/components/PriceDisplay';

interface MobileAncillaryModalFooterProps {
  setIsOverlayOpened: Dispatch<SetStateAction<boolean>>;
  totalAmount: number;
}

const MobileAncillaryModalFooter: FC<MobileAncillaryModalFooterProps> = ({
  setIsOverlayOpened,
  totalAmount,
}) => {
  const { watch } = useFormContext<AncillaryValues>();
  const selectedLegId = watch('selectedLegId');
  const selectedAncillaries = watch('selectedAncillaries');

  return (
    <div className="fixed bottom-0 flex w-full flex-col bg-white">
      <Divider variant="medium" />
      <div className="flex flex-col gap-4 p-4">
        <div className="flex gap-2">
          <Icons.travelPass height={20} width={20} className="text-primary" />
          <Typography variant="body1" className="text-neutral">
            <TransText i18nKey="totalPrice" />:
          </Typography>
          <Typography variant="subtitle" className="text-primary">
            <PriceDisplay amount={totalAmount} />
          </Typography>
        </div>
        <div className="flex gap-2">
          <Button
            size="large"
            fullWidth
            variant="tertiary"
            data-testid="ancillary-selection-cancel-button"
            className="rounded-lg"
            onClick={() => setIsOverlayOpened(false)}
          >
            <Typography variant="button">
              <TransText i18nKey="cancel" />
            </Typography>
          </Button>
          <Button
            size="large"
            fullWidth
            data-testid="ancillary-selection-submit-button"
            className="rounded-lg"
            disabled={!selectedLegId || selectedAncillaries.length === 0}
            type="submit"
          >
            <Icons.check />
            <Typography variant="button">
              <TransText i18nKey={selectedLegId ? 'confirm' : 'selectLeg'} />
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileAncillaryModalFooter;
