import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import {
  SheetRoot,
  SheetContent,
  SheetHeader,
  SheetTrigger,
  SheetDescription,
  SheetTitle,
} from '@/components/primitives/Sheet';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { Loadable } from '@/components/Loadable';
import { useSelector } from '@/store/utils';
import { addAncillaryLoadingSelector } from '@/features/loading/loadingSelectors';
import { TransText } from '@/i18n/trans/text';
import { Button } from '@/components/primitives/Button';

interface MobileAncillaryModalProps {
  children: ReactNode;
  isOverlayOpened: boolean;
  setIsOverlayOpened: Dispatch<SetStateAction<boolean>>;
  totalAmount: number;
}

export const MobileAncillaryModal: FC<MobileAncillaryModalProps> = ({
  isOverlayOpened,
  setIsOverlayOpened,
  children,
}) => {
  const loading = useSelector(addAncillaryLoadingSelector);

  return (
    <SheetRoot open={isOverlayOpened} onOpenChange={setIsOverlayOpened}>
      <SheetTrigger asChild>
        <Button
          data-testid="add-new-ancillary-button"
          variant="secondary"
          size="medium"
          className="rounded-md"
        >
          <Icons.addCircle height={16} width={16} />
          <TransText i18nKey="addNew" />
        </Button>
      </SheetTrigger>
      <SheetContent side="bottom" className="h-screen p-0">
        <Loadable loading={loading} overlay>
          <SheetDescription className="sr-only">
            <TransText i18nKey="addExtras" />
          </SheetDescription>
          <SheetTitle className="sr-only">
            <TransText i18nKey="addExtras" />
          </SheetTitle>
          <SheetHeader className="h-14 min-h-14 shadow-md">
            <div className="ml-4 flex h-full items-center gap-1">
              <Button
                variant="icon"
                onClick={() => setIsOverlayOpened(false)}
                data-testid="ancillary-sheet-close-button"
              >
                <Icons.arrowLeft className="text-dark" />
              </Button>
              <Typography variant="subtitle">
                <TransText i18nKey="addExtras" />
              </Typography>
            </div>
          </SheetHeader>
          {children}
        </Loadable>
      </SheetContent>
    </SheetRoot>
  );
};
