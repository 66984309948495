import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Header from '@/components/header';
import Purchase from '@/routes/purchase';
import * as Sentry from '@sentry/react';
import Manage from '@/routes/manage';
import { ToastContainer } from 'react-toastify';
import Success from '@/routes/success';
import { useDispatch, useSelector } from '@/store/utils';
import { useCallback, useEffect } from 'react';
import {
  getConfiguration,
  getConsents,
  setTenant,
} from '@/features/configuration/configurationActions';
import Login from '@/routes/login';
import SignUp from '@/routes/signup';
import { AuthProvider } from '@/context/AuthContextProvider';
import Account from '@/routes/account';
import Tickets from '@/routes/account/Tickets';
import { TENANT_OPTIONS } from '@/utils/tenant';
import { getProfile } from '@/features/profile/profileActions';
import { PageLayout } from '@/components/layouts/PageLayout';
import SignupConfirmation from '@/routes/signup/SignupConfirmation';
import { isLocalDevelopment } from '@/utils/isLocalDev';
import { Loadable } from '@/components/Loadable';
import { configurationLoadingSelector } from '@/features/loading/loadingSelectors';
import getApiInstance from '@/api';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const dispatch = useDispatch();
  const loading = useSelector(configurationLoadingSelector);

  const handleInitialLoad = useCallback(async () => {
    await getApiInstance();

    dispatch(getConfiguration());
    dispatch(getConsents());
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    handleInitialLoad();
  }, [handleInitialLoad]);

  useEffect(() => {
    const tenant =
      TENANT_OPTIONS.find(
        ({ value }) => value === import.meta.env.VITE_APP_TENANT
      ) ?? TENANT_OPTIONS[0];

    dispatch(setTenant(tenant));
  }, [dispatch]);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Loadable loading={loading} overlay>
          <div className="flex min-h-screen min-w-mobile flex-col">
            <ToastContainer
              position="top-center"
              theme="colored"
              pauseOnFocusLoss={false}
              pauseOnHover={false}
              hideProgressBar
            />
            <Header />
            <main className="flex w-full flex-1">
              <PageLayout>
                <SentryRoutes>
                  <Route path="/" element={<Purchase />} />
                  <Route path="/manage-booking" element={<Manage />} />
                  <Route path="/success" element={<Success />} />

                  {isLocalDevelopment() && (
                    <>
                      <Route path="/login" element={<Login />} />
                      <Route path="/signup" element={<SignUp />} />
                      <Route
                        path="/signup/confirmation"
                        element={<SignupConfirmation />}
                      />
                      <Route path="/account" element={<Account />}>
                        <Route path="tickets" element={<Tickets />} />
                      </Route>
                    </>
                  )}

                  <Route path="*" element={<Navigate to="/" />} />
                </SentryRoutes>
              </PageLayout>
            </main>
          </div>
        </Loadable>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
