import type { FC } from 'react';
import MobileNavMenu from '@/components/header/nav-menu/MobileNavMenu';
import RegularNavMenu from '@/components/header/nav-menu/RegularNavMenu';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { TransText } from '@/i18n/trans/text';
import type { NavigationItem } from '@/types/navigation';
import { clearManageFlowBooking } from '@/features/manage/manageActions';
import { useDispatch } from '@/store/utils';

const Header: FC = () => {
  const dispatch = useDispatch();
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const menuItems: Array<NavigationItem> = [
    { path: '/', name: <TransText i18nKey="purchase" />, testId: 'purchase' },
    {
      path: '/manage-booking',
      name: <TransText i18nKey="manageBooking" />,
      testId: 'manage-booking',
      onMenuItemClick: () => dispatch(clearManageFlowBooking()),
    },
  ];

  const NavMenu = isLaptopOrBigger ? RegularNavMenu : MobileNavMenu;

  return (
    <header className="mx-auto flex h-16 w-full max-w-laptop flex-shrink-0 items-center bg-background px-4">
      <NavMenu menuItems={menuItems} />
    </header>
  );
};

export default Header;
