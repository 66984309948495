import type { FC } from 'react';
import type { OfferDTO } from '@/types/dto';
import type { OfferMapByLegId, OfferMapItem } from '@/types/offer';
import { useDispatch, useSelector } from '@/store/utils';
import type { ActionCreatorWithPayload, Selector } from '@reduxjs/toolkit';
import type { RootState } from '@/store';
import BaseOfferCard from '@/components/purchase/card/BaseOfferCard';

interface OfferCardProps {
  offer: OfferDTO;
  legId?: string;
  journeyDetails: {
    originStopName?: string;
    destinationStopName?: string;
  };
  updateSelectedOfferMap: ActionCreatorWithPayload<OfferMapItem>;
  selectedOfferMapByLegIdSelector: Selector<RootState, OfferMapByLegId>;
}

const OfferCard: FC<OfferCardProps> = ({
  offer,
  legId,
  updateSelectedOfferMap,
  selectedOfferMapByLegIdSelector,
}) => {
  const dispatch = useDispatch();
  const selectedOfferMapByLegId = useSelector(selectedOfferMapByLegIdSelector);
  const selectedOffer = legId ? selectedOfferMapByLegId[legId] : null;
  const isSelected = Boolean(selectedOffer?.id === offer.id);

  if (!offer) {
    return null;
  }

  const handleSelect = () => {
    if (offer.id && offer.price && legId) {
      dispatch(updateSelectedOfferMap({ legId, offer }));
    }
  };

  return (
    <BaseOfferCard
      title={offer.productSummary || ''}
      amount={offer?.price?.amount || 0}
      isSelected={isSelected}
      onSelect={handleSelect}
      description={offer.productDescriptiveTexts?.[0]?.description}
      isRefundable={offer.refundable === 'YES'}
      isChangeable={offer.exchangeable === 'YES'}
      showPassengerInfo={true}
      testId="select-offer"
    />
  );
};

export default OfferCard;
