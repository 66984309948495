import { type FC } from 'react';
import type { CheckoutValues } from '@/utils/zodSchema';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import sanitizeMarkup from '@/utils/sanitizeMarkup';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/primitives/Form';
import { cn } from '@/utils/cn';
import { Checkbox } from '@/components/primitives/Checkbox';

interface ConsentsProps {}

const Consents: FC<ConsentsProps> = () => {
  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext<CheckoutValues>();

  const { fields: consents, update } = useFieldArray({
    control,
    name: 'consents',
  });

  return (
    <div className="flex flex-col gap-4" data-testid="consents">
      {consents.map((consent, index) => {
        const markup = consent.description || '';
        const hasError = !!errors.consents?.[index];

        return (
          <FormField
            control={control}
            name={`consents.${index}`}
            key={index}
            render={() => (
              <FormItem>
                <FormControl label={null} hideLabel>
                  <div className="flex items-center gap-3">
                    <Checkbox
                      className={cn('h-6 w-6', {
                        'border-error-text': hasError,
                      })}
                      checked={consent.checked}
                      onCheckedChange={(checked) => {
                        update(index, {
                          ...consent,
                          checked: checked === true,
                        });
                        trigger(`consents.${index}`);
                      }}
                    />
                    <FormLabel required={consent.isMandatory}>
                      {markup ? (
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: sanitizeMarkup(markup),
                          }}
                          variant="body1"
                          className={cn('text-dark prose-a:text-blue-600', {
                            'text-error-text': hasError,
                          })}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          className={cn('text-dark', {
                            'text-error-text': hasError,
                          })}
                        >
                          <TransText i18nKey="undefinedConsentDescription" />
                        </Typography>
                      )}
                    </FormLabel>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
        );
      })}
    </div>
  );
};

export default Consents;
