import { Typography } from '@/components/primitives/Typography';
import { type FC } from 'react';
import PriceDisplay from '@/components/PriceDisplay';

interface RefundSummaryItemProps {
  isTotal?: boolean;
  icon: React.ReactNode;
  title: React.ReactNode;
  amount: number | undefined;
}

const RefundSummaryItem: FC<RefundSummaryItemProps> = ({
  isTotal,
  icon,
  title,
  amount,
}) => {
  return (
    <div className="flex flex-1 flex-row items-center justify-between">
      <div className="flex flex-row justify-items-center gap-2">
        {icon}
        <Typography variant={isTotal ? 'body1-bold' : 'body2-bold'}>
          {title}
        </Typography>
      </div>
      <Typography
        variant={isTotal ? 'body1-bold' : 'body2-bold'}
        className="text-primary"
      >
        <PriceDisplay amount={amount} />
      </Typography>
    </div>
  );
};

export default RefundSummaryItem;
