import type { FC, ReactNode } from 'react';
import {
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetRoot,
  SheetTitle,
} from '@/components/primitives/Sheet';
import { Button } from '@/components/primitives/Button';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';

interface MobileRefundModalProps {
  children: ReactNode;
  title: ReactNode;
  isOpen: boolean;
  handleOpenChange: (isOpen: boolean) => void;
  footer: ReactNode;
}

const MobileRefundModal: FC<MobileRefundModalProps> = ({
  children,
  title,
  isOpen,
  handleOpenChange,
  footer,
}) => {
  return (
    <SheetRoot open={isOpen} onOpenChange={handleOpenChange}>
      <SheetContent side="bottom" className="flex h-full flex-col border-none">
        <SheetTitle className="sr-only">{title}</SheetTitle>
        <SheetDescription className="sr-only">
          <TransText i18nKey="refundModalDescription" />
        </SheetDescription>
        <SheetHeader className="h-14 min-h-14 shadow-md">
          <div className="ml-4 flex h-full items-center gap-1">
            <Button
              data-testid="refund-sheet-back-button"
              variant="icon"
              onClick={() => handleOpenChange(false)}
              aria-label="Close"
            >
              <Icons.arrowLeft className="text-dark" />
            </Button>
            <Typography variant="subtitle">{title}</Typography>
          </div>
        </SheetHeader>

        <div className="flex-grow overflow-auto px-4">{children}</div>
        <SheetFooter className="border-t border-neutral-light p-4">
          {footer}
        </SheetFooter>
      </SheetContent>
    </SheetRoot>
  );
};

export default MobileRefundModal;
