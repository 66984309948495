import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  initReactI18next,
  useTranslation as useNativeTranslation,
} from 'react-i18next';
import I18NextLocizeBackend from 'i18next-locize-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { getTenantConfiguration } from '@/utils/tenant';

const NAMESPACE = 'webshop-booking-engine';

export const localeConfigurations: Record<
  'en' | 'it',
  {
    label: string;
    dateFormat: string;
    timeFormat: string;
  }
> = {
  en: { label: 'English', dateFormat: 'dd/MM/yyyy', timeFormat: 'HH:mm' },
  it: { label: 'Italiano', dateFormat: 'dd/mm/yyyy', timeFormat: 'HH:mm' },
};

export type I18nLanguage = keyof typeof localeConfigurations;

if (!localStorage.getItem('i18nextLng')) {
  const configuration = getTenantConfiguration(import.meta.env.VITE_APP_TENANT);

  if (configuration?.language) {
    localStorage.setItem('i18nextLng', configuration.language);
  }
}

i18n
  .use(I18NextLocizeBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    fallbackLng: 'en',
    supportedLngs: Object.keys(localeConfigurations),
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
    },
    defaultNS: NAMESPACE,
    ns: NAMESPACE,
    // debug: true,
    initImmediate: false,
    backend: {
      version: 'release_3.2.0',
      projectId: '42618d3a-3808-4dd3-b18b-ee0649d90021',
    },
  });

export const getLanguage = () =>
  i18n.language ?? window.localStorage.i18nextLng ?? 'en';

export const getAdyenLocale = () => {
  const lang = getLanguage();
  const langMap: Record<string, string> = {
    ar: 'ar-AR',
    bg: 'bg-BG',
    ca: 'ca-ES',
    cs: 'cs-CZ',
    da: 'da-DK',
    de: 'de-DE',
    el: 'el-GR',
    en: 'en-US',
    es: 'es-ES',
    et: 'et-EE',
    fi: 'fi-FI',
    fr: 'fr-FR',
    hr: 'hr-HR',
    hu: 'hu-HU',
    is: 'is-IS',
    it: 'it-IT',
    ja: 'ja-JP',
    ko: 'ko-KR',
    lt: 'lt-LT',
    lv: 'lv-LV',
    nl: 'nl-NL',
    no: 'no-NO',
    pl: 'pl-PL',
    'pt-BR': 'pt-BR',
    'pt-PT': 'pt-PT',
    ro: 'ro-RO',
    ru: 'ru-RU',
    sk: 'sk-SK',
    sl: 'sl-SI',
    sv: 'sv-SE',
    'zh-CN': 'zh-CN',
    'zh-TW': 'zh-TW',
  };
  return langMap[lang] || langMap.en;
};

export const useTranslation = () =>
  useNativeTranslation(NAMESPACE, {
    i18n,
  });
