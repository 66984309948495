import type { FC, ReactNode } from 'react';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/primitives/Dialog';
import { TransText } from '@/i18n/trans/text';

interface RegularRefundModalProps {
  children: ReactNode;
  title: ReactNode;
  isOpen: boolean;
  handleOpenChange: (isOpen: boolean) => void;
  footer: ReactNode;
}

const RegularRefundModal: FC<RegularRefundModalProps> = ({
  children,
  title,
  isOpen,
  handleOpenChange,
  footer,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="w-[480px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription className="sr-only">
          <TransText i18nKey="refundModalDescription" />
        </DialogDescription>
        <DialogBody>
          <div className="flex-grow overflow-auto p-4">{children}</div>
        </DialogBody>
        <DialogFooter>{footer}</DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RegularRefundModal;
