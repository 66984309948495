import type { FC } from 'react';
import { TENANT_OPTIONS } from '@/utils/tenant';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import type { TenantOption } from '@/types/tenant';
import {
  PopoverContent,
  PopoverItem,
  PopoverPrimitiveRoot,
  PopoverTrigger,
} from '@/components/primitives/Popover';
import { Button } from '@/components/primitives/Button';
import { useDispatch, useSelector } from '@/store/utils';
import { setTenant } from '@/features/configuration/configurationActions';
import { tenantSelector } from '@/features/configuration/configurationSelector';

const RegularTenantSelect: FC = () => {
  const selectedTenant = useSelector(tenantSelector);
  const dispatch = useDispatch();

  const handleTenantChange = (tenant: TenantOption) => {
    dispatch(setTenant(tenant));
  };

  return (
    <PopoverPrimitiveRoot>
      <PopoverTrigger asChild>
        <Button
          data-testid="tenant-select-trigger"
          variant="ghostLink"
          className="group flex gap-2"
        >
          <Icons.powerSocket />
          <Typography variant="button">{selectedTenant.label}</Typography>
          <Icons.chevronDown
            height={16}
            width={16}
            className="transition-transform duration-200 group-data-[state=open]:rotate-180"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-40" align="end">
        {TENANT_OPTIONS.map((option) => (
          <PopoverItem
            key={option.value}
            active={option.value === selectedTenant.value}
            onClick={() => handleTenantChange(option)}
            data-testid={`tenant-select-item-${option.value}`}
          >
            <Typography variant="body1">{option.label}</Typography>
            {option.value === selectedTenant.value && (
              <Icons.checkCircle
                height={16}
                width={16}
                className="text-success-text"
              />
            )}
          </PopoverItem>
        ))}
      </PopoverContent>
    </PopoverPrimitiveRoot>
  );
};

export default RegularTenantSelect;
