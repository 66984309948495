import type { FC } from 'react';
import { useCallback } from 'react';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import type { BookingItem } from '@/types/booking';
import { cn } from '@/utils/cn';
import { getPassengerName } from '@/utils/passenger';
import PriceDisplay from '@/components/PriceDisplay';

export type BookingItemRowProps = BookingItem & {
  icon: keyof typeof Icons;
};

const BookingItemRow: FC<BookingItemRowProps> = ({
  icon,
  description,
  amount,
  status,
  ...rest
}) => {
  const IconComponent = Icons[icon];
  const isExchanged = status === 'EXCHANGED';
  const isRefunded = status === 'REFUNDED';

  const renderTripInfo = useCallback(() => {
    if ('originStopName' in rest && 'destinationStopName' in rest) {
      const { originStopName, destinationStopName } = rest;

      if (originStopName && destinationStopName) {
        return (
          <div className="ml-6 flex flex-wrap items-center text-neutral">
            <Typography variant="body2">{originStopName}</Typography>
            <Icons.oneWay className="mx-1 mt-0.5 h-3 w-3" />
            <Typography variant="body2">{destinationStopName}</Typography>
            <div className="flex items-center text-neutral">
              <Icons.dot className="mx-1 mt-0.5" />
              <Typography variant="body2" translate="no">
                {getPassengerName(rest, rest.passengerIndex)}
              </Typography>
            </div>
          </div>
        );
      }
    }

    return null;
  }, [rest]);

  if (isExchanged) {
    return null;
  }

  return (
    <div className="px-4 py-2">
      <div className="flex items-center justify-between">
        <div className="flex items-end gap-2">
          <IconComponent
            className={cn('h-4 w-4 text-primary', {
              'text-neutral': isRefunded,
            })}
          />

          <Typography
            variant="body1-bold"
            className={cn({
              'text-neutral line-through decoration-error-border': isRefunded,
            })}
          >
            {description}
          </Typography>
        </div>
        <Typography
          variant="body2-bold"
          className={cn('text-nowrap text-primary', {
            'text-neutral line-through decoration-error-border': isRefunded,
          })}
        >
          <PriceDisplay amount={amount} />
        </Typography>
      </div>
      {renderTripInfo()}
    </div>
  );
};

export default BookingItemRow;
