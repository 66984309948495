import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { Icons } from '@/components/icons';
import Divider, { type DividerVariant } from '@/components/primitives/Divider';
import { useBackgroundTheme } from '@/hooks/useBackgroundTheme';
import { isLightBackground } from '@/utils/color';
import { cn } from '@/utils/cn';

interface TransferTimeProps {
  formattedTime: string | null;
  arrivalStation?: string;
  departureStation?: string;
  className?: string;
  dividerVariant?: DividerVariant;
}

const TransferTime: FC<TransferTimeProps> = ({
  formattedTime,
  arrivalStation,
  departureStation,
  dividerVariant,
}) => {
  const isSameStation = arrivalStation === departureStation;
  const backgroundTheme = useBackgroundTheme();
  const isLight = isLightBackground(backgroundTheme);

  return (
    <div className="my-3 flex w-full flex-row items-center px-4">
      <Divider variant={dividerVariant} />
      <div className="flex flex-row items-center gap-4 px-2">
        <Typography
          variant="body2-bold"
          className={cn(
            'whitespace-nowrap',
            isLight ? 'text-neutral' : 'text-neutral-light'
          )}
        >
          {formattedTime} <TransText i18nKey="transferTime" />
        </Typography>
        {!isSameStation && (
          <div className="flex flex-row gap-1.5">
            <Typography variant="body2">{arrivalStation}</Typography>
            <Icons.transfer className="text-neutral-light" />
            <Typography variant="body2">{departureStation}</Typography>
          </div>
        )}
      </div>
      <Divider variant={dividerVariant} />
    </div>
  );
};

export default TransferTime;
