import type { FC } from 'react';
import { CardPrimitiveRoot } from '@/components/primitives/Card';
import { Typography } from '@/components/primitives/Typography';
import Divider from '@/components/primitives/Divider';
import { Icons } from '@/components/icons';
import { TransText } from '@/i18n/trans/text';
import SummaryContent from '@/components/booking-summary/SummaryContent';
import type { BookingSummaryData } from '@/hooks/useBookingSummaryData';
import type { Flow } from '@/types/booking';
import { useBooking } from '@/hooks/useBooking';
import PriceDisplay from '@/components/PriceDisplay';

interface BookingSummaryProps extends BookingSummaryData {
  flow: Flow;
}

const RegularBookingSummary: FC<BookingSummaryProps> = (props) => {
  const booking = useBooking(props.flow);

  return (
    <CardPrimitiveRoot className="w-[320px]">
      <SummaryContent {...props} />
      <Divider variant="medium" />
      <div className="flex flex-row justify-between p-4">
        <div className="flex gap-2">
          <Icons.shoppingCart className="text-primary" />
          <Typography variant="body1-bold">
            <TransText i18nKey="totalPrice" />
          </Typography>
        </div>
        <Typography variant="subtitle" className="text-primary">
          <PriceDisplay
            amount={
              props.flow === 'purchase'
                ? booking?.provisionalPrice?.amount
                : booking?.confirmedPrice?.amount
            }
          />
        </Typography>
      </div>
    </CardPrimitiveRoot>
  );
};

export default RegularBookingSummary;
