import { cn } from '@/utils/cn';
import type { FC, ReactNode } from 'react';
import { BackgroundThemeProvider } from '@/context/BackgroundThemeContextProvider';

interface FooterProps {
  actionButtons: ReactNode;
  content?: ReactNode;
}

const Footer: FC<FooterProps> = ({ actionButtons, content }) => {
  if (!actionButtons && !content) {
    return null;
  }

  return (
    <BackgroundThemeProvider backgroundTheme="white">
      <footer
        className={cn(
          'fixed bottom-0 left-0 right-0 flex w-full justify-center border-t bg-white text-dark'
        )}
      >
        <div
          id="footer-wrapper"
          className={cn(
            'flex w-full max-w-laptop flex-col gap-4 p-4 laptop:flex-row laptop:items-center'
          )}
        >
          {content && <div id="footer-content">{content}</div>}
          {actionButtons && (
            <div
              id="footer-buttons"
              className="flex w-full gap-2 laptop:justify-end"
            >
              {actionButtons}
            </div>
          )}
        </div>
      </footer>
    </BackgroundThemeProvider>
  );
};

export default Footer;
