import { type FC, useCallback, useEffect } from 'react';
import { usePurchaseSteps } from '@/hooks/usePurchaseSteps';
import { STEP } from '@/utils/purchase';
import { SearchWidgetWrapper } from '@/components/search-widget';
import { SearchWidgetProvider } from '@/context/SearchWidgetContextProvider';
import { useDispatch, useSelector } from '@/store/utils';
import { purchaseFlowBookingSelector } from '@/features/purchase/purchaseSelectors';
import {
  getPurchaseFlowBookingById,
  setActiveStep,
} from '@/features/purchase/purchaseActions';
import { persistor } from '@/store';
import { PersistGate } from 'redux-persist/integration/react';
import { usePageTitle } from '@/hooks/usePageTitle';

interface PurchaseProps {}

const Purchase: FC<PurchaseProps> = () => {
  const dispatch = useDispatch();
  const { steps, activeStep } = usePurchaseSteps();
  const purchaseFlowBooking = useSelector(purchaseFlowBookingSelector);
  usePageTitle('purchase');

  const handlePurchaseBookingFetching = useCallback(async () => {
    try {
      await dispatch(
        getPurchaseFlowBookingById(purchaseFlowBooking?.id)
      ).unwrap();
      dispatch(setActiveStep(STEP.Checkout));
    } catch {
      persistor.purge();
    }
  }, [dispatch, purchaseFlowBooking?.id]);

  useEffect(() => {
    if (
      purchaseFlowBooking?.id &&
      Object.keys(purchaseFlowBooking).length === 1
    ) {
      handlePurchaseBookingFetching();
    }
  }, [handlePurchaseBookingFetching, purchaseFlowBooking]);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <SearchWidgetProvider>
        <div className="sticky top-0 z-50 bg-background">
          {STEP.Checkout !== activeStep && <SearchWidgetWrapper />}
        </div>
        {steps[activeStep]}
      </SearchWidgetProvider>
    </PersistGate>
  );
};

export default Purchase;
