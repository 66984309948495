import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import ExpandableText from '@/components/ExpandableText';
import { TransText } from '@/i18n/trans/text';
import Changeability from '@/components/purchase/card/Changeability';
import Refundability from '@/components/purchase/card/Refundability';
import type { MultiRideAccountDTO, TravelPassAccountDTO } from '@/types/dto';
import type { DecoratedTravelPassDetails } from '@/types/manage';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import TicketCuticle from '@/components/manage/TicketCuticle';
import { cn } from '@/utils/cn';
import Divider from '@/components/primitives/Divider';
import NonTripOfferValidity from '@/components/manage/travel-passes/NonTripOfferValidity';
import { Badge } from '@/components/primitives/Badge';
import { Icons } from '@/components/icons';
import PriceDisplay from '@/components/PriceDisplay';
import { BackgroundThemeProvider } from '@/context/BackgroundThemeContextProvider';

interface TravelPassDetailsProps {
  data: DecoratedTravelPassDetails<MultiRideAccountDTO | TravelPassAccountDTO>;
}

const TravelPassDetails: FC<TravelPassDetailsProps> = ({ data }) => {
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const zone =
    data.travelAccount?.regionalConstraint?.regionalValidities[0].zone;
  const origin = zone?.entryStation?.name;
  const destination = zone?.terminalStation?.name;
  const productDescriptiveText = data.productDescriptiveTexts?.[0];
  const passengerCard = data.bookingPassengers?.find(
    (y) => y.id === data.admissionPassengerIds?.[0]
  );
  const isRefunded = data.status === 'REFUNDED';

  const renderPassengerInfo = () => (
    <div className="flex flex-col">
      <Typography variant="body2-bold">
        {data.travelAccount?.holder?.firstName}
        &nbsp;
        {data.travelAccount?.holder?.lastName}
      </Typography>
      {!!passengerCard?.cards?.length && (
        <Typography variant="body2">
          {`${passengerCard.cards[0].name}`}: &nbsp;
          {passengerCard.cards[0].number}
        </Typography>
      )}
    </div>
  );

  const renderPrice = () => (
    <div data-testid="travel-pass-price">
      <Typography variant="body1-bold" className="text-primary">
        <PriceDisplay amount={data.price?.amount} />
      </Typography>
    </div>
  );

  const renderRouteInfo = () => (
    <div
      className={cn('bg-primary-pale text-primary laptop:rounded-tr-lg', {
        'bg-neutral-light': isRefunded,
      })}
    >
      <div className="flex flex-row flex-wrap items-center px-4 py-3">
        <Typography
          variant="subtitle"
          className={cn('min-w-fit', {
            'text-neutral': isRefunded,
          })}
        >
          {origin}
        </Typography>
        <div className="mx-1.5 shrink-0">—</div>
        <Typography
          variant="subtitle"
          className={cn('min-w-fit', {
            'text-neutral': isRefunded,
          })}
        >
          {destination}
        </Typography>
      </div>
    </div>
  );

  const renderRefundBadge = () => (
    <Badge
      variant="negative"
      className={cn('h-5', { 'absolute -top-3 right-3': !isLaptopOrBigger })}
    >
      <Icons.ticketReturn height={16} width={16} />
      <TransText i18nKey="refunded" />
    </Badge>
  );

  const renderTravelPassInfo = () => (
    <>
      <div className="flex flex-row items-center gap-1">
        <Typography variant="body2" className="text-neutral">
          <TransText i18nKey="travelPass" />:
        </Typography>
        <Typography
          variant="body2"
          className={cn({
            'text-neutral line-through': isRefunded,
          })}
        >
          {data.travelAccount?.number}
        </Typography>
      </div>
      {!isLaptopOrBigger && (
        <div className="mt-2 flex flex-row items-center gap-3">
          <Changeability condition={data.exchangeable === 'YES'} />
          <Refundability condition={data.refundable === 'YES'} />
        </div>
      )}
    </>
  );

  return (
    <BackgroundThemeProvider backgroundTheme="white">
      <div
        className={cn(
          'flex rounded-lg border border-neutral-light bg-background bg-white text-dark',
          isLaptopOrBigger ? 'flex-row' : 'flex-col',
          { 'bg-neutral-pale': isRefunded }
        )}
      >
        <div
          className={cn(
            'flex flex-col',
            isLaptopOrBigger ? 'w-2/3 px-4 py-3' : 'w-full p-3'
          )}
        >
          <div className="flex h-full flex-col">
            <div className="mb-3 flex justify-between">
              <Typography
                variant="subtitle"
                className={cn({
                  'text-neutral line-through': isRefunded,
                })}
                asChild
              >
                <h2>
                  {productDescriptiveText?.shortDescription ??
                    productDescriptiveText?.description}
                </h2>
              </Typography>
              {isRefunded && isLaptopOrBigger && renderRefundBadge()}
            </div>
            <ExpandableText text={productDescriptiveText?.description} />
          </div>

          <div
            className={cn({
              'mt-auto flex flex-row items-center justify-between':
                isLaptopOrBigger,
              'mt-4 flex flex-col': !isLaptopOrBigger,
            })}
          >
            {renderTravelPassInfo()}
            {isLaptopOrBigger && (
              <div className="flex flex-row items-center gap-3">
                <Changeability condition={data.exchangeable === 'YES'} />
                <Refundability condition={data.refundable === 'YES'} />
              </div>
            )}
          </div>
        </div>

        {isLaptopOrBigger ? (
          <>
            <TicketCuticle isVertical={true} />
            <div className="flex w-2/5 flex-col">
              {renderRouteInfo()}
              <div className="flex-grow px-4 py-3">
                <NonTripOfferValidity data={data} isRefunded={isRefunded} />
              </div>
              <div className="flex flex-row items-center justify-between px-4 pb-3">
                {renderPassengerInfo()}
                {renderPrice()}
              </div>
            </div>
          </>
        ) : (
          <>
            <Divider variant="medium" />
            <div className="w-full">
              {renderRouteInfo()}
              <div className="px-3 py-3">
                <NonTripOfferValidity data={data} isRefunded={isRefunded} />
              </div>
              <TicketCuticle isVertical={false} />
              <div className="relative flex flex-row items-center justify-between px-3 py-3">
                {isRefunded && renderRefundBadge()}
                {renderPassengerInfo()}
                {renderPrice()}
              </div>
            </div>
          </>
        )}
      </div>
    </BackgroundThemeProvider>
  );
};

export default TravelPassDetails;
