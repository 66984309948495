import React, { useMemo } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { useSelector } from '@/store/utils';
import {
  refundDetailsSelector,
  refundSelectedNonTripAdmissionsSelector,
} from '@/features/manage/manageSelectors';
import RefundSummary from '@/components/manage/refund/summary/RefundSummary';
import HighlightedRow from '@/components/booking-summary/HighlightedRow';
import { Icons } from '@/components/icons';
import { useBookingNonTripOfferDetails } from '@/hooks/useBookingNonTripOfferDetails';
import { Flow } from '@/types/booking';
import { type DecoratedTravelPassDetails } from '@/types/manage';
import TravelPassAccountValidity from '@/components/manage/travel-passes/TravelPassAccountValidity';
import PriceDisplay from '@/components/PriceDisplay';

interface NonTripOffersByRouteAndDates {
  origin?: string;
  destination?: string;
  validFrom: DecoratedTravelPassDetails['validFrom'];
  validUntil: DecoratedTravelPassDetails['validUntil'];
  nonTripOffers: Array<DecoratedTravelPassDetails>;
}

export const RefundNonTripOverview: React.FC = () => {
  const { travelPass, multiRide } = useBookingNonTripOfferDetails(
    Flow.manageBooking
  );
  const selectedNonTripAdmissions = useSelector(
    refundSelectedNonTripAdmissionsSelector
  );
  const refundSummary = useSelector(refundDetailsSelector);

  const nonTripOffersByRouteAndDates = useMemo(
    () =>
      [...travelPass, ...multiRide].reduce(
        (acc, nonTripOffer) => {
          if (!nonTripOffer.id || !selectedNonTripAdmissions[nonTripOffer.id]) {
            return acc;
          }

          const zone =
            nonTripOffer.travelAccount?.regionalConstraint
              ?.regionalValidities[0].zone;

          const { validFrom, validUntil } = nonTripOffer;
          const origin = zone?.entryStation?.name;
          const destination = zone?.terminalStation?.name;
          const key = `${origin}_${destination}_${validFrom}_${validUntil}`;

          if (acc[key]) {
            acc[key].nonTripOffers.push(nonTripOffer);
          } else {
            acc[key] = {
              origin,
              destination,
              validFrom,
              validUntil,
              nonTripOffers: [nonTripOffer],
            };
          }

          return acc;
        },
        {} as Record<string, NonTripOffersByRouteAndDates>
      ),
    [multiRide, selectedNonTripAdmissions, travelPass]
  );

  if (!refundSummary || !refundSummary.refundOffers) {
    return null;
  }

  return (
    <div>
      <RefundSummary
        refundSummary={refundSummary}
        admissions={Object.values(selectedNonTripAdmissions)}
      />
      <Typography variant="subtitle" className="my-5">
        <TransText i18nKey="youAreAboutToRefundFollowingItems" />
      </Typography>
      <div className="flex flex-col rounded-lg rounded-b-none border border-neutral-light">
        <HighlightedRow className="rounded-t-lg">
          <div className="flex gap-2">
            <Icons.transportFrom className="h-5 w-5 text-primary" />
            <Typography variant="body1-bold">
              <TransText i18nKey="travelPassDetails" />
            </Typography>
          </div>
        </HighlightedRow>
        <div className="flex flex-col gap-4 p-4">
          {Object.entries(nonTripOffersByRouteAndDates).map(([key, item]) => (
            <div key={key}>
              <div className="mb-3 flex flex-row flex-wrap items-center text-primary">
                <Typography variant="body1-bold" className="min-w-fit">
                  {item.origin}
                </Typography>
                <div className="mx-1.5 shrink-0">—</div>
                <Typography variant="body1-bold" className="min-w-fit">
                  {item.destination}
                </Typography>
              </div>
              {item.validFrom && item.validUntil && (
                <TravelPassAccountValidity
                  validFrom={item.validFrom}
                  validUntil={item.validUntil}
                  className="mb-4"
                />
              )}
              <div className="flex flex-col gap-2">
                {item.nonTripOffers.map((nonTripOffer) => (
                  <div key={nonTripOffer.id} className="flex flex-col">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <Icons.ticket className="text-primary" />
                        <Typography variant="body1-bold">
                          {nonTripOffer.shortDescription}
                        </Typography>
                      </div>
                      <Typography variant="body2-bold" className="text-primary">
                        <PriceDisplay amount={nonTripOffer.price?.amount} />
                      </Typography>
                    </div>
                    <div className="ml-6 flex items-center gap-1 text-neutral">
                      {nonTripOffer.travelAccount?.holder?.firstName && (
                        <Typography variant="body2">
                          {nonTripOffer.travelAccount?.holder?.firstName}
                        </Typography>
                      )}
                      {nonTripOffer.travelAccount?.holder?.lastName && (
                        <Typography variant="body2">
                          {nonTripOffer.travelAccount?.holder?.lastName}
                        </Typography>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RefundNonTripOverview;
